<template>
    <app-layout>
       <div>
           <div class="col-xs-12">
               <div class="page-title-box">
                   <h4 class="page-title">Catégories Formation</h4>
                   <ol class="breadcrumb p-0 m-0">
                       <li>
                           <inertia-link href="/admin/dashboard">Dashboard</inertia-link>
                       </li>
                       <li>
                           <inertia-link href="/admin/formation">Formations </inertia-link>
                       </li>
                       <li class="active">
                           Catégorie
                       </li>
                   </ol>
                   <div class="clearfix"></div>
               </div>
           </div>
           <div class="col-xs-12 col-lg-6 ">
               <div class="col-xs-12 m-b-3 row">
                   <span class="h3">Catégories de Formation </span>
                   <button type="button" class="btn btn-purple waves-light waves-effect w-md m-b-5 pull-right" @click="ajouterCategorie()">Ajouter</button>

                   <div>

                       <datatable
                               :fetch-url="$page.props.app.url+'admin/categories'"
                               :columns="categorieColumns"
                               :color="'table-bordered-purple'"
                               :btns="btnColumns"
                               @modifier="editingCategorie($event)"
                               @supprimer="deletingCategorie($event)"
                               ref="categorieTable"
                       ></datatable>
                   </div>
               </div>
           </div>
           <div class="col-xs-12 col-lg-6 ">
           <div class="col-xs-12 m-b-3 row">
               <span class="h3">Domaines de Formation </span>
               <button type="button" class="btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right" @click="ajouterDomaine()">Ajouter</button>

               <div>

                   <datatable
                           :fetch-url="$page.props.app.url+'admin/domaines'"
                           :columns="domaineColumns"
                           :color="'table-bordered-inverse'"
                           :btns="btnColumns"
                           @modifier="editingDomaine($event)"
                           @supprimer="deletingDomaine($event)"
                           ref="domaineTable"
                   ></datatable>
               </div>
           </div>
       </div>
           <categorie-form :show="showCategorieForm" :editMode="editCategorie" :categorieToEdit="categorieToEdit" @close="close"  @updated="reloadCategorie()" ref="categorieForm"></categorie-form>
           <domaine-form :show="showDomaineForm" :editMode="editDomaine" :domaineToEdit="domaineToEdit" @close="close"  @updated="reloadDomaines()"></domaine-form>
       </div>
    </app-layout>
</template>

<script>
    import AppLayout from "../../../Layout/AppLayout";
    import CategorieForm from "./CategorieForm";
    import DomaineForm from "./Domaine/DomaineForm";
    import Datatable from "@/Shared/Datatable";
    export default {
        components:{
            AppLayout,
            CategorieForm,
            DomaineForm,
            Datatable
        },
        props: ['data'],
        data() {
            return {
                show: false,
                showDomaineForm:false,
                editDomaine: false,
                domaineToEdit: '',
                showCategorieForm:false,
                editCategorie: false,
                categorieToEdit: '',
                domaineColumns:[{name: 'id', titre: '#ID'},{name: 'fr_libelle_domaine', titre: 'LIBEELLE FR'},
                    {name: 'en_libelle_domaine', titre: 'LIBEELLE EN'}],
                categorieColumns:[{name: 'id', titre: '#ID'},{name: 'fr_libelle_categorie', titre: 'libelle_categorie'},
                    {name: 'en_libelle_categorie', titre: 'libelle_categorie'},
                    {name: 'domaine', titre: 'LIBEELLE DOMAINE'},],
                btnColumns:[
                    {name: 'Editer', action: 'edit', classes: 'btn btn-xs btn-warning'},
                    {name: 'Supprimer', action: 'delete', classes: 'btn btn-xs btn-danger'}],
            }
        },
        methods: {
            close(){
                this.$emit('close');
                this.show = false;
                this.showDomaineForm = false;
                this.showCategorieForm = false;
            },
            reloadDomaines(){
                this.$refs.domaineTable.reloadData();
                this.$refs.categorieForm.loadDomaines();
                this.$refs.categorieTable.reloadData();

            },
            reloadCategorie(){
                this.$refs.categorieTable.reloadData();
            },
            loadDomaines(){
                /*axios.get('/admin/domaines/all')
                    .then(response => {
                        this.domaines = response.data;
                        //tail.select(".tail-select", { /!* Your Options *!/ });
                    });*/
            },

            editingDomaine(data){
                this.domaineToEdit = data;
                this.editDomaine = true;
                this.showDomaineForm = true;
            },
            ajouterDomaine(){
                this.showDomaineForm = true;
            },
            editingCategorie(data){
                this.categorieToEdit = data;
                this.editCategorie = true;
                this.showCategorieForm = true;
            },
            ajouterCategorie(){
                this.showCategorieForm = true;
            },
            deletingDomaine (data){
                Swal.fire({
                    title: 'Êtes-vous sûr?',
                    text: "Vous ne pourrez plus revenir en arrière!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui, supprimez-le!'
                }).then((result) => {
                    if(result.value){
                        data._method = 'DELETE';

                        this.$inertia.post('/admin/domaine/'+data.id, data,{
                            onSuccess: (page) => {
                                this.reloadDomaines();
                            },
                            onError: (page) => {

                            },
                            preserveScroll: true,
                        }).catch(() => {


                        });
                    }
                });
            },
            deletingCategorie (data){
                Swal.fire({
                    title: 'Êtes-vous sûr?',
                    text: "Vous ne pourrez plus revenir en arrière!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui, supprimez-le!'
                }).then((result) => {
                    if(result.value){
                        data._method = 'DELETE';

                        this.$inertia.post('/admin/categorie/'+data.id, data,{
                            onSuccess: (page) => {
                                this.reloadCategorie();
                            },
                            onError: (page) => {

                            },
                            preserveScroll: true,
                        }).catch(() => {


                        });
                    }
                });
            },

        },
        mounted() {
            //this.loadDomaines();
        }
    }
</script>

