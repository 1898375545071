<template>
    <div class="responsive-table">

    <vuetable ref="vuetable"
              api-url="https://vuetable.ratiw.net/api/users"
              :fields="fields"
    >
        <div slot="gender-slot" slot-scope="props">
            <button class="btn btn-success"> dldlldl</button>
        </div>
    </vuetable>
    </div>

</template>

<script>
    import Vuetable from 'vuetable-2/src/components/Vuetable'

    export default {
        components: {
            Vuetable
        },
        data(){
            return{
                fields: ['name', 'address.line2', 'address.zipcode',{
                    name: "gender-slot",
                    title: '<i class="grey heterosexual icon"></i>Gender',
                    titleClass: "center aligned",
                    dataClass: "center aligned",
                    width: "15%",
                },]
            }
        }
    }
</script>