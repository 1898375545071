<template>
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left">
            <a href="index.html" class="logo"><span>Admin-<span>GBG</span></span><i class="mdi mdi-layers"></i></a>
            <!-- Image logo -->
            <!--<a href="index.html" class="logo">-->
            <!--<span>-->
            <!--<img src="assets/images/logo.png" alt="" height="30">-->
            <!--</span>-->
            <!--<i>-->
            <!--<img src="assets/images/logo_sm.png" alt="" height="28">-->
            <!--</i>-->
            <!--</a>-->
        </div>

        <!-- Button mobile view to collapse sidebar menu -->
        <div class="navbar navbar-default" role="navigation">
            <div class="container">

                <!-- Navbar-left -->
                <ul class="nav navbar-nav navbar-left">
                    <li>
                        <button class="button-menu-mobile open-left waves-effect">
                            <i class="mdi mdi-menu"></i>
                        </button>
                    </li>
                    <!--<li class="hidden-xs">
                        <form role="search" class="app-search">
                            <input type="text" placeholder="Search..."
                                   class="form-control">
                            <a href=""><i class="fa fa-search"></i></a>
                        </form>
                    </li>
                    <li class="hidden-xs">
                        <a href="#" class="menu-item">New</a>
                    </li>
                    <li class="dropdown hidden-xs">
                        <a data-toggle="dropdown" class="dropdown-toggle menu-item" href="#" aria-expanded="false">English
                            <span class="caret"></span></a>
                        <ul role="menu" class="dropdown-menu">
                                <li><a href="#">French</a></li>
                        </ul>
                    </li>-->
                </ul>

                <!-- Right(Notification) -->
                <ul class="nav navbar-nav navbar-right">
                    <!--  <li>

                          <a href="#" class="right-menu-item dropdown-toggle" data-toggle="dropdown">
                              <i class="mdi mdi-bell"></i>
                              <span class="badge up bg-success">4</span>
                          </a>

                          <ul class="dropdown-menu dropdown-menu-right arrow-dropdown-menu arrow-menu-right dropdown-lg user-list notify-list">
                              <li>
                                  <h5>Notifications</h5>
                              </li>
                              <li>
                                  <a href="#" class="user-list-item">
                                      <div class="icon bg-info">
                                          <i class="mdi mdi-account"></i>
                                      </div>
                                      <div class="user-desc">
                                          <span class="name">New Signup</span>
                                          <span class="time">5 hours ago</span>
                                      </div>
                                  </a>
                              </li>
                              <li>
                                  <a href="#" class="user-list-item">
                                      <div class="icon bg-danger">
                                          <i class="mdi mdi-comment"></i>
                                      </div>
                                      <div class="user-desc">
                                          <span class="name">New Message received</span>
                                          <span class="time">1 day ago</span>
                                      </div>
                                  </a>
                              </li>
                              <li>
                                  <a href="#" class="user-list-item">
                                      <div class="icon bg-warning">
                                          <i class="mdi mdi-settings"></i>
                                      </div>
                                      <div class="user-desc">
                                          <span class="name">Settings</span>
                                          <span class="time">1 day ago</span>
                                      </div>
                                  </a>
                              </li>
                              <li class="all-msgs text-center">
                                  <p class="m-0"><a href="#">See all Notification</a></p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <a href="#" class="right-menu-item dropdown-toggle" data-toggle="dropdown">
                              <i class="mdi mdi-email"></i>
                              <span class="badge up bg-danger">8</span>
                          </a>

                          <ul class="dropdown-menu dropdown-menu-right arrow-dropdown-menu arrow-menu-right dropdown-lg user-list notify-list">
                              <li>
                                  <h5>Messages</h5>
                              </li>
                              <li>
                                  <a href="#" class="user-list-item">
                                      <div class="avatar">
                                          &lt;!&ndash;
                                                                                          <img src="assets/images/users/avatar-2.jpg" alt="">
                                          &ndash;&gt;
                                      </div>
                                      <div class="user-desc">
                                          <span class="name">Patricia Beach</span>
                                          <span class="desc">There are new settings available</span>
                                          <span class="time">2 hours ago</span>
                                      </div>
                                  </a>
                              </li>
                              <li>
                                  <a href="#" class="user-list-item">
                                      <div class="avatar">
                                          &lt;!&ndash;
                                                                                          <img src="assets/images/users/avatar-3.jpg" alt="">
                                          &ndash;&gt;
                                      </div>
                                      <div class="user-desc">
                                          <span class="name">Connie Lucas</span>
                                          <span class="desc">There are new settings available</span>
                                          <span class="time">2 hours ago</span>
                                      </div>
                                  </a>
                              </li>
                              <li>
                                  <a href="#" class="user-list-item">
                                      <div class="avatar">
                                          &lt;!&ndash;
                                                                                          <img src="assets/images/users/avatar-4.jpg" alt="">
                                          &ndash;&gt;
                                      </div>
                                      <div class="user-desc">
                                          <span class="name">Margaret Becker</span>
                                          <span class="desc">There are new settings available</span>
                                          <span class="time">2 hours ago</span>
                                      </div>
                                  </a>
                              </li>
                              <li class="all-msgs text-center">
                                  <p class="m-0"><a href="#">See all Messages</a></p>
                              </li>
                          </ul>
                      </li>-->

                    <li>
                        <a href="javascript:void(0);" @click="logout()" class="right-bar-toggle right-menu-item">
                            <i class="mdi mdi-lock"></i>
                        </a>
                    </li>

                    <li class="dropdown user-box">
                        <a href="" class="dropdown-toggle waves-effect user-link" data-toggle="dropdown" aria-expanded="true">
                            <!--
                                                                <img src="assets/images/users/avatar-1.jpg" alt="user-img" class="img-circle user-img">
                            -->
                        </a>

                        <ul class="dropdown-menu dropdown-menu-right arrow-dropdown-menu arrow-menu-right user-list notify-list">
                            <li>
                                <h5>Hi, John</h5>
                            </li>
                            <li><a href="javascript:void(0)"><i class="ti-user m-r-5"></i> Profile</a></li>
                            <li><a href="javascript:void(0)"><i class="ti-settings m-r-5"></i> Settings</a></li>
                            <li><a href="javascript:void(0)"><i class="ti-lock m-r-5"></i> Lock screen</a></li>
                            <li><a href="javascript:void(0)"><i class="ti-power-off m-r-5"></i> Logout</a></li>
                        </ul>
                    </li>

                </ul> <!-- end navbar-right -->

            </div><!-- end container -->
        </div><!-- end navbar -->
    </div>
</template>
<script>
    export default {
        methods:{
            logout() {
                axios.post(route('logout')).then(response => {
                    window.location = '/';
                })
            },
        },
        mounted () {
            $('.animate-number').each(function(){
                $(this).animateNumbers($(this).attr("data-value"), true, parseInt($(this).attr("data-duration")));
            });

            //RUN RESIZE ITEMS
            //$(window).resize(debounce(resizeitems,100));
            $("body").trigger("resize");

            // right side-bar toggle
            $('.right-bar-toggle').on('click', function(e){

                $('#wrapper').toggleClass('right-bar-enabled');
            });
            $('.open-left').on('click', function(e){
                $("#wrapper").toggleClass("enlarged");
                $("#wrapper").addClass("forced");

                if($("#wrapper").hasClass("enlarged") && $("body").hasClass("fixed-left")) {
                    $("body").removeClass("fixed-left").addClass("fixed-left-void");
                } else if(!$("#wrapper").hasClass("enlarged") && $("body").hasClass("fixed-left-void")) {
                    $("body").removeClass("fixed-left-void").addClass("fixed-left");
                }

                if($("#wrapper").hasClass("enlarged")) {
                    $(".left ul").removeAttr("style");
                } else {
                    $(".subdrop").siblings("ul:first").show();
                }

                // toggle_slimscroll(".slimscrollleft");
                $("body").trigger("resize");
            });


        },
    }
</script>