<template>
    <app-layout>
        <div>
            <div class="col-xs-12">
                <div class="page-title-box">
                    <h4 class="page-title">Catégories Formation</h4>
                    <ol class="breadcrumb p-0 m-0">
                        <li>
                            <inertia-link href="/admin/dashboard">Dashboard</inertia-link>
                        </li>
                        <li>
                            <inertia-link href="/admin/formation">Formations </inertia-link>
                        </li>
                        <li class="active">
                            Catégorie
                        </li>
                    </ol>
                    <div class="clearfix"></div>
                </div>
            </div>

            <div class="col-xs-12 ">
                <div class="col-xs-12 m-b-3 row">
                    <span class="h3">Formation </span>
                    <button type="button" class="btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right" @click="ajouterFormation()">Ajouter</button>
                    <div>
                        <datatable
                                :fetch-url="$page.props.app.url+'admin/formations'"
                                :columns="formationColumns"
                                :links="columnsLink"
                                :color="'table-bordered-pink'"
                                :btns="btnColumns"
                                @modifier="editingFormation($event)"
                                @supprimer="deletingFormation($event)"
                                @mettreenavant="mettreEnAvant($event)"
                                ref="formationTable"
                        ></datatable>
                    </div>
                </div>
            </div>
            <formation-form :show="showFormationForm" :editMode="editFormation" :formationToEdit="formationToEdit" @close="close"  @updated="reloadFormations()"></formation-form>
        </div>
    </app-layout>
</template>

<script>
    import AppLayout from "../../Layout/AppLayout";
    import FormationForm from "./Pages/FormationForm";
    import Datatable from "@/Shared/Datatable";
    export default {
        components:{
            AppLayout,
            FormationForm,
            Datatable
        },
        props: ['data'],
        data() {
            return {
                show: false,
                showFormationForm:false,
                editFormation: false,
                formationToEdit: '',
                formationColumns:[{name: 'id', titre: '#ID'},
                    {name: 'fr_titre_formation', titre: 'Titre FR'},{name: 'en_titre_formation', size:50, titre: 'Titre EN'},
                    {name: 'categorie', titre: 'Catégorie'},{name: 'fr_pour_qui', size:50}
                    ,{name: 'fr_duree', titre: 'DUREE'}],
                btnColumns:[
                    {name: 'Mettre en avant', action: 'avant', classes: 'btn btn-xs btn-success'},
                    {name: 'Editer', action: 'edit', classes: 'btn btn-xs btn-warning'},
                    {name: 'Supprimer', action: 'delete', classes: 'btn btn-xs btn-danger'}],
                columnsLink:[
                    {name: 'Programme', url: '/admin/programme-formation/', classes: 'btn btn-xs btn-teal'}],
            }
        },
        methods: {
            close(){
                this.$emit('close');
                this.show = false;
                this.showFormationForm = false;
            },
            reloadFormations(){
                this.$refs.formationTable.reloadData();
            },
            editingFormation(data){
                this.formationToEdit = data;
                this.editFormation = true;
                this.showFormationForm = true;
            },
            ajouterFormation(){
                this.showFormationForm = true;
            },
            mettreEnAvant (data){
                Swal.fire({
                    title: 'Êtes-vous sûr?',
                    text: "Vous êtes sur le point de mettre la fomation "+data.fr_titre_formation+" en avant!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui, Mettre en avant!'
                }).then((result) => {
                    if(result.value){
                        data._method = 'PUT';
                        data.newValue = !data.en_avant;
                        this.$inertia.post('/admin/formation-en-avant/'+data.id, data,{
                            onSuccess: (page) => {
                                this.reloadFormations();
                            },
                            onError: (page) => {

                            },
                            preserveScroll: true,
                        }).catch(() => {


                        });
                    }
                });
            },
            deletingFormation (data){
                Swal.fire({
                    title: 'Êtes-vous sûr?',
                    text: "Vous ne pourrez plus revenir en arrière!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui, supprimez-le!'
                }).then((result) => {
                    if(result.value){
                        data._method = 'DELETE';

                        this.$inertia.post('/admin/formation/'+data.id, data,{
                            onSuccess: (page) => {
                                this.reloadFormations();
                            },
                            onError: (page) => {

                            },
                            preserveScroll: true,
                        }).catch(() => {


                        });
                    }
                });
            },


        },
        mounted() {
            //this.loadDomaines();
        }
    }
</script>

