<template>
    <div v-show="message">
        <p class="text-sm-left text-danger" v-if="$page.props.errors[formName]">
            <span v-if="$page.props.errors[formName][message]">
                 {{$page.props.errors[formName][message][0] }}
            </span>

        </p>
    </div>
</template>

<script>
    export default {
        props: ['message', 'formName'],

    }
</script>