<template>
    <button :type="type"
            v-on="$listeners"
            :disabled="disableButton"
            :class="[btnClass, classes]">
        <slot></slot>

    </button>
</template>
<style scoped>
    .base-button + .base-button {
        margin-left: 1em;
    }

    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }

    .base-spinner {
        position: relative;
        overflow: hidden;
    }

    .base-spinner:before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        background-color: inherit;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
    }

    .base-spinner:after {
        content: "";
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 4px solid rgba(255, 255, 255, 0.45);
        border-top-color: inherit;
        animation: spinner 0.6s linear infinite;
    }

</style>
<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: ""
            },
            classes: {
                type: String,
                default: ""
            },
        },
        data() {
            return {
                loading: false,
                disableButton: this.disabled
            };
        },

        methods: {
            startLoading() {
                this.loading = true;
                this.disableButton = true;
            },
            stopLoading() {
                this.loading = false;
                this.disableButton = false;
            }
        },

        computed: {
            btnClass() {
                return {
                    "base-spinner loader": this.loading == true,
                    "cursor-not-allowed": this.disableButton == true,

                };

            },




        }
    };
</script>
