<template>
    <app-layout>
        <div>
            <div class="col-xs-12">
                <div class="page-title-box">
                    <h4 class="page-title">Demandes Recrutement</h4>
                    <ol class="breadcrumb p-0 m-0">
                        <li>
                            <inertia-link href="/admin/dashboard">Dashboard</inertia-link>
                        </li>
                        <li class="active">
                            Demandes de Recrutement
                        </li>
                    </ol>
                    <div class="clearfix"></div>
                </div>
            </div>

            <div class="col-xs-12 ">
                <div class="col-xs-12 m-b-3 row">
                    <span class="h3">Demandes de Recrutement </span>
                    <div>
                        <datatable
                                :fetch-url="$page.props.app.url+'admin/demandes-recrutements'"
                                :columns="recrutementColumns"
                                :links="columnsLink"
                                :color="'table-bordered-pink'"
                                :btns="btnColumns"
                                @show="showRecrutement($event)"
                                @modifier="suivreDemandeRec($event)"
                                @supprimer="deletingRecrutement($event)"
                                ref="recrutementTable"
                        ></datatable>
                    </div>
                </div>
            </div>
            <show-demande-recrutement :show="showDemande" :recrutementToShow="recrutementToShow" @close="close" ></show-demande-recrutement>
            <suivre-recrutement-form :show="showRecrutementForm" :recrutementToEdit="recrutementToEdit" @close="closeForm"  ></suivre-recrutement-form>
        </div>
    </app-layout>
</template>

<script>
    import AppLayout from "../../Layout/AppLayout";
    import Datatable from "@/Shared/Datatable";
    import ShowDemandeRecrutement from "@/Pages/Recrutement/ShowDemandeRecrutement";
    import SuivreRecrutementForm from "@/Pages/Recrutement/SuivreRecrutementForm";
    export default {
        components:{
            AppLayout,
            ShowDemandeRecrutement,
            SuivreRecrutementForm,
            Datatable
        },
        props: ['data'],
        data() {
            return {
                show: false,
                showDemande:false,
                showRecrutementForm: false,
                recrutementToShow: '',
                editFormation: false,
                recrutementToEdit: '',
                recrutementColumns:[{name: 'id', titre: '#ID'},
                    {name: 'prenom'},{name: 'nom', titre: 'Titre EN'},
                    {name: 'poste'},{name: 'nombre_poste', size:25}
                    ,{name: 'type_contrat'}],
                btnColumns:[
                    {name: 'Voir', action: 'show', classes: 'btn btn-xs btn-teal'},
                    {name: 'Suivre', action: 'edit', classes: 'btn btn-xs btn-brown'},
                    {name: 'Cloturer', action: 'delete', classes: 'btn btn-xs btn-danger'}],
                columnsLink:[
                   // {name: 'Programme', url: '/admin/programme-formation/', classes: 'btn btn-xs btn-teal'}
                    ],
                form: this.$inertia.form({
                    activite : '',
                    description : '',
                    demande_id : '',
                    clickVoir : 1,
                })
            }
        },
        methods: {
            close(){
                this.showDemande = false;
            },
            closeForm(){
                this.showRecrutementForm = false;
            },
            reloadData(){
                this.$refs.recrutementTable.reloadData();
            },
            suivreDemandeRec(data){
                this.recrutementToEdit = data;
                this.showRecrutementForm = true;
            },
            showRecrutement(data){
                this.form.activite = 'Consulter la demande';
                this.form.demande_id = data.id;

                    this.$inertia.post('/admin/suivi-recrutement', this.form, {

                    onSuccess: (page) => {
                        this.recrutementToShow = data;
                        this.showDemande = true;
                    },
                    preserveScroll: true,
                });

            },
            deletingRecrutement (data){
                Swal.fire({
                    title: 'Êtes-vous sûr?',
                    text: "Vous ne pourrez plus revenir en arrière!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui, cloturer la demande!'
                }).then((result) => {
                    if(result.value){
                        data._method = 'PUT';

                        this.$inertia.post('/admin/demande-recrutement/'+data.id, data,{
                            onSuccess: (page) => {
                                this.reloadData();
                            },
                            onError: (page) => {

                            },
                            preserveScroll: true,
                        }).catch(() => {


                        });
                    }
                });
            },


        },
        mounted() {
            //this.loadDomaines();
        }
    }
</script>

