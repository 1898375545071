<template>
    <div class="left side-menu">
        <div class="sidebar-inner slimscrollleft">

            <!--- Sidemenu -->
            <div id="sidebar-menu">
                <ul>
                    <li class="menu-title">Navigation</li>

                    <li class="">
                        <a href="javascript:void(0);" class="waves-effect"><i class="mdi mdi-view-dashboard"></i><span class="label label-success pull-right">2</span> <span> Dashboard </span> </a>

                    </li>

                    <li class="has_sub">
                        <a href="javascript:void(0);" class="waves-effect " :class="{'active subdrop': route().current('formations')||route().current('sessions')||route().current('categories')}" ><i class="mdi mdi-group"></i> <span> Formations </span> <span class="menu-arrow"></span></a>
                        <ul class="list-unstyled">
                            <li><inertia-link :href="route('formations')" :class="{'active': route().current('formations')}" >Formations</inertia-link></li>
                            <li><inertia-link :href="route('categories')" :class="{'active': route().current('categories')}">Catégories</inertia-link></li>
                            <inertia-link :href="route('sessions')" :class="{'active': route().current('sessions')}">Sessions</inertia-link>
                        </ul>
                    </li>
                    <li></li>
                    <li class="has_sub">
                        <a href="javascript:void(0);" class="waves-effect " :class="{'active': route().current('categoriesTypesActualites')||route().current('admin.magazine')}" ><i class="mdi mdi-book-open-page-variant"></i> <span> Magazine </span> <span class="menu-arrow"></span></a>
                        <ul class="list-unstyled">
                            <li><inertia-link :href="route('admin.magazine')" :class="{'active': route().current('magazine')}">Actualités</inertia-link></li>
                            <li><inertia-link :href="route('categoriesTypesActualites')" :class="{'active': route().current('categoriesTypesActualites')}">Type et Catégorie</inertia-link></li>
                        </ul>
                    </li>

                    <li>
                        <inertia-link :href="route('demandesSession')" :class="{'active': route().current('demandesSession')}"><i class="mdi mdi-account-multiple"></i> Inscrip. Session </inertia-link>
                    </li>
                    <li class="has_sub">
                        <a href="javascript:void(0);" class="waves-effect " :class="{'active ': route().current('demandesRecrutement')||route().current('demandesInterim')||route().current('demandesFormation')||route().current('demandesConseil')}" ><i class="mdi mdi-group"></i> <span> Demandes </span> <span class="menu-arrow"></span></a>
                        <ul class="list-unstyled">
                            <li>
                                <inertia-link :href="route('demandesRecrutement')" :class="{'active': route().current('demandesRecrutement')}">Recrutement </inertia-link>
                            </li>
                            <li>
                                <inertia-link :href="route('demandesInterim')" :class="{'active': route().current('demandesInterim')}">Intérim </inertia-link>
                            </li>
                            <li>
                                <inertia-link :href="route('demandesFormation')" :class="{'active': route().current('demandesFormation')}">Formation </inertia-link>
                            </li>
                            <li>
                                <inertia-link :href="route('demandesConseil')" :class="{'active': route().current('demandesConseil')}">Conseil </inertia-link>
                            </li>
                        </ul>
                    </li>

                </ul>
            </div>
            <!-- Sidebar -->
            <div class="clearfix"></div>

            <!--<div class="help-box">
                <h5 class="text-muted m-t-0">For Help ?</h5>
                <p class=""><span class="text-custom">Email:</span> <br/> support@support.com</p>
                <p class="m-b-0"><span class="text-custom">Call:</span> <br/> (+123) 123 456 789</p>
            </div>-->

        </div>
        <!-- Sidebar -left -->

    </div>
</template>
<script>
    export default {
        mounted() {
            $('#sidebar-menu a').on('click', function(e){
                if(!$("#wrapper").hasClass("enlarged")){
                    if($(this).parent().hasClass("has_sub")) {

                    }
                    if(!$(this).hasClass("subdrop")) {
                        // hide any open menus and remove all other classes
                        $("ul",$(this).parents("ul:first")).slideUp(350);
                        $("a",$(this).parents("ul:first")).removeClass("subdrop");
                        $("#sidebar-menu .pull-right i").removeClass("md-remove").addClass("md-add");

                        // open our new menu and add the open class
                        $(this).next("ul").slideDown(350);
                        $(this).addClass("subdrop");
                        $(".pull-right i",$(this).parents(".has_sub:last")).removeClass("md-add").addClass("md-remove");
                        $(".pull-right i",$(this).siblings("ul")).removeClass("md-remove").addClass("md-add");
                    }else if($(this).hasClass("subdrop")) {
                        $(this).removeClass("subdrop");
                        $(this).next("ul").slideUp(350);
                        $(".pull-right i",$(this).parent()).removeClass("md-remove").addClass("md-add");
                    }
                }
            });
        }
    }
</script>