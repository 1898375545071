<template>
    <div>
        <modal :show="show" @close="close()">
            <template #modalHead>
                <div>
                    Ajout d'une catégorie de formation
                </div>
            </template>
            <template #modalBody>
                <div>
                    <form class="form-horizontal">
                        <div class="form-group">
                            <label class="col-xs-12 ">Libelle de la categorie (en Français)</label>
                            <div class="col-xs-12">
                                <input type="text" v-model="form.fr_libelle_categorie" class="form-control" >
                                <input-error :formName="'addCategorie'" :message="'fr_libelle_categorie'" class="mt-2" />

                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Libelle de la categorie (en anglais)</label>
                            <div class="col-xs-12">
                                <input type="text" v-model="form.en_libelle_categorie" class="form-control">
                                <input-error :formName="'addCategorie'" :message="'en_libelle_categorie'" class="mt-2" />

                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Sélectionner un domaine</label>
                            <div class="col-xs-12">

                                <v-select  placeholder="Selectionnez un domaine" label="fr_libelle_domaine" :options="domaines"
                                           :reduce="domaine => domaine.id" v-model="form.domaine_id" ></v-select>
                                <input-error :formName="'addCategorie'" :message="'domaine_id'" class="mt-2" />

                            </div>
                        </div>
                    </form>
                </div>
            </template>
            <template #modalFooter>
                <div>
                    <loading-btn v-if="!editMode" @click="addCategorie" ref="submitButton" :classes="'btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right'" >Ajouter</loading-btn>
                    <loading-btn v-if="editMode" @click="updateCategorie" ref="submitButton" :classes="'btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right'" >Enregistrer</loading-btn>
                </div>
            </template>
        </modal>
    </div>
</template>
<script>
    import Modal from '@/Shared/Modal';
    import InputError from "@/Shared/InputError";
    import LoadingBtn from "@/Shared/LoadingBtn";
    export default {
        components: {
            Modal,
            InputError,
            LoadingBtn
        },
        data(){
            return{
                domaines: [],
                form: this.$inertia.form({
                    fr_libelle_categorie : '',
                    en_libelle_categorie : '',
                    domaine_id:''
                }, {
                    bag: 'addCategorie',

                })
            }
        },
        props:{
            show : {
                required: true,
            },
            editMode: {
                default: false,
            },
            categorieToEdit: {
                default: false,
            }
        },
        methods:{
            close(){
                this.form.reset();
                this.$emit('close');
            },
            addCategorie(){
                this.$inertia.post('/admin/categorie', this.form, {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => {
                        this.$refs.submitButton.stopLoading();
                    },
                    onSuccess: (page) => {
                        if (page.props.errors.addCategorie) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()

                        this.close();
                    },
                    preserveScroll: true,
                });
            },
            updateCategorie(){
                this.form._method = 'PUT';
                this.$inertia.post('/admin/categorie/'+this.categorieToEdit.id, this.form , {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => this.$refs.submitButton.stopLoading(),
                    onSuccess: (page) => {
                        if (page.props.errors.addCategorie) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()
                        this.close();
                    },
                    preserveScroll: true

                })
            },
            loadDomaines(){
                axios.get('/admin/domaines/all')
                    .then(response => {
                        this.domaines = response.data.data;
                    });
            }
        },
        watch: {
            categorieToEdit() {
                this.form.fr_libelle_categorie = this.categorieToEdit.fr_libelle_categorie;
                this.form.en_libelle_categorie = this.categorieToEdit.en_libelle_categorie;
                this.form.domaine_id = this.categorieToEdit.domaine_id;
            }
        },

        mounted() {
            this.loadDomaines();
        }
    }
</script>