<template>
    <div>
        <modal :show="show" @close="close()">
            <template #modalHead>
                <div>
                    Ajout d'un type d'actualité
                </div>
            </template>
            <template #modalBody>
                <div>
                    <form class="form-horizontal">
                        <div class="form-group">
                            <label class="col-xs-12 ">Libelle type (en Français)</label>
                            <div class="col-xs-12">
                                <input type="text" v-model="form.fr_libelle" class="form-control" >
                                <input-error :formName="'addTypeMag'" :message="'fr_libelle'" class="mt-2" />

                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Libelle type (en anglais)</label>
                            <div class="col-xs-12">
                                <input type="text" v-model="form.en_libelle" class="form-control">
                                <input-error :formName="'addTypeMag'" :message="'en_libelle'" class="mt-2" />

                            </div>
                        </div>

                    </form>
                </div>
            </template>
            <template #modalFooter>
                <div>
                    <loading-btn v-if="!editMode"  ref="submitButton" :classes="'btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right'" >Ajouter</loading-btn>
                    <loading-btn v-if="editMode" @click="updateTypeMag" ref="submitButton" :classes="'btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right'" >Enregistrer</loading-btn>
                </div>
            </template>
        </modal>
    </div>
</template>
<script>
    import Modal from '@/Shared/Modal';
    import InputError from "@/Shared/InputError";
    import LoadingBtn from "@/Shared/LoadingBtn";
    export default {
        components: {
            Modal,
            InputError,
            LoadingBtn
        },
        data(){
            return{
                form: this.$inertia.form({
                    fr_libelle : '',
                    en_libelle : '',
                }, {
                    bag: 'addTypeMag',

                })
            }
        },
        props:{
            show : {
                required: true,
            },
            editMode: {
                default: false,
            },
            typeMagToEdit: {
                default: false,
            }
        },
        methods:{
            close(){
                this.form.reset();
                this.$emit('close');
            },
            /*addTypeMag(){
                this.$inertia.post('/admin/type-actualite', this.form, {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => {
                        this.$refs.submitButton.stopLoading();
                    },
                    onSuccess: (page) => {
                        if (page.props.errors.addTypeMag) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()

                        this.close();
                    },
                    preserveScroll: true,
                });
            },*/
            updateTypeMag(){
                this.form._method = 'PUT';
                this.$inertia.post('/admin/type-actualite/'+this.typeMagToEdit.id, this.form , {
                    onSuccess: (page) => {
                        if (page.props.errors.addTypeMag) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()
                        this.close();
                    },
                    preserveScroll: true

                })
            },
           /* loadDomaines(){
                axios.get('/admin/domaines/all')
                    .then(response => {
                        this.domaines = response.data.data;
                    });
            }*/
        },
        watch: {
            typeMagToEdit() {
                this.form.fr_libelle = this.typeMagToEdit.fr_libelle;
                this.form.en_libelle = this.typeMagToEdit.en_libelle;
            }
        },

        mounted() {
            //this.loadDomaines();
        }
    }
</script>