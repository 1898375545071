<template>
    <app-layout>
        <h1>Dashboard</h1>
    </app-layout>
</template>

<script>
    import AppLayout from "../Layout/AppLayout";
    export default {
        components:{
          AppLayout
        },
        props: ['data'],
        data() {
            return {
                editMode: false,
                form: {
                    name: null,
                    phone: null,
                },
            }
        },
        methods: {
            openModal: function () {
                $('#modal').modal('show')
            },
            closeModal: function () {
                $('#modal').modal('hide')
                this.reset();
                this.editMode=false;
            },
            reset: function () {
                this.form = {
                    name: null,
                    phone: null,
                }
            },
            save: function (data) {
                this.$inertia.post('/admin/contacts', data)
                this.reset();
                this.closeModal();
                this.editMode = false;
            },
            edit: function (data) {
                this.form = Object.assign({}, data);
                this.editMode = true;
                this.openModal();
            },
            update: function (data) {
                if (!confirm('Sure')) return;
                data._method = 'PUT';
                this.$inertia.post('/admin/contacts/' + data.id, data)
                this.reset();
                this.closeModal();
            },
            deleteRow: function (data) {
                if (!confirm('Sure')) return;
                data._method = 'DELETE';
                this.$inertia.post('/admin/contacts/' + data.id, data)
                this.reset();
                this.closeModal();
            }
        }
    }
</script>
