<template>
    <div>
        <modal :show="show" @close="close()">
            <template #modalHead>
                <div>
                    Ajout d'une formation
                </div>
            </template>
            <template #modalBody>
                <div>
                    <form class="form-horizontal">
                        <div class="form-group">
                            <label class="col-xs-12 ">Titre du programme (Français)</label>
                            <div class="col-xs-12">
                                <input type="text" placeholder="Titre du programme" v-model="form.fr_titre_programme" class="form-control" value="Some text value...">
                                <input-error :formName="'addProgramme'" :message="'fr_titre_programme'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Titre du programme (en anglais)</label>
                            <div class="col-xs-12">
                                <input type="text" placeholder="Program title" v-model="form.en_titre_programme" class="form-control" value="Some text value...">
                                <input-error :formName="'addProgramme'" :message="'en_titre_programme'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Contenu du programme (en français)</label>
                            <div class="col-xs-12">
                                <ckeditor v-model="form.fr_contenu" :config="editorConfig"></ckeditor>
                                <input-error :formName="'addProgramme'" :message="'fr_contenu'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Contenu du programme (en anglais)</label>
                            <div class="col-xs-12">
                                <ckeditor v-model="form.en_contenu" :config="editorConfig"></ckeditor>
                                <input-error :formName="'addProgramme'" :message="'en_contenu'" class="mt-2" />
                            </div>
                        </div>
                    </form>
                </div>
            </template>
            <template #modalFooter>
                <div>
                    <loading-btn v-if="!editMode" @click="addProgramme" ref="submitButton" :classes="'btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right'" >Ajouter</loading-btn>
                    <loading-btn v-if="editMode" @click="updateProgramme" ref="submitButton" :classes="'btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right'" >Enregistrer</loading-btn>
                </div>
            </template>
        </modal>
    </div>
</template>
<style>
    .align-items-center {
        display: flex;
        align-items: center;
    }
</style>
<script>
    import Modal from '@/Shared/Modal';
    import InputError from "@/Shared/InputError";
    import LoadingBtn from "@/Shared/LoadingBtn";
    import AppLayout from "@/Layout/AppLayout";
    export default {
        components:{
            AppLayout,
            InputError,
            LoadingBtn,
            Modal
        },
        props: {
            formation : {
                required: true,
            },
            show : {
                required: true,
            },
            editMode: {
                default: false,
            },
            programmeToEdit: {
                default: false,
            }
        },
        data() {
            return {
                editorConfig: {
                    language: 'fr',
                    height: 300
                },
                form: this.$inertia.form({
                    fr_titre_programme : '',
                    en_titre_programme : '',
                    fr_contenu: '',
                    en_contenu: '',
                    formation_id: this.formation.id
                }, {
                    bag: 'addProgramme',

                })
            }
        },
        methods: {
            close(){
                this.form.reset();
                this.$emit('close');
            },
            addProgramme(){
                this.$inertia.post('/admin/save-programme', this.form, {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => {
                        this.$refs.submitButton.stopLoading();
                    },
                    onSuccess: (page) => {
                        if (page.props.errors.addProgramme) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()

                        this.close();
                    },
                    preserveScroll: true,
                });
            },
            updateProgramme(){
                this.form._method = 'PUT';

                this.$inertia.post('/admin/programme/'+this.programmeToEdit.id, this.form , {
                    onSuccess: (page) => {
                        if (page.props.errors.addProgramme) {
                            return;
                        }
                        this.$emit('updated');
                        this.close();
                    },
                    preserveScroll: true

                })
            }

        },
        watch: {
            programmeToEdit() {
                this.form.fr_titre_programme = this.programmeToEdit.fr_titre_programme;
                this.form.en_titre_programme = this.programmeToEdit.en_titre_programme;
                this.form.fr_contenu = this.programmeToEdit.fr_contenu;
                this.form.en_contenu = this.programmeToEdit.en_contenu;
            }
        },
        mounted() {
            if (this.programmeToEdit){
                this.form.fr_titre_programme = this.programmeToEdit.fr_titre_programme;
                this.form.en_titre_programme = this.programmeToEdit.en_titre_programme;
                this.form.fr_contenu = this.programmeToEdit.fr_contenu;
                this.form.en_contenu = this.programmeToEdit.en_contenu;
            }
        }
    }
</script>