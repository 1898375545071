<template>
    <transition name="modal">
        <div class="modal-mask "  v-if="show">
            <div class="modal-container col-xs-12 row " @click.stop>
                <div class="modal-header col-xs-12 row">
                    <span class="close" @click="close()">X</span>
                    <h3>
                        <slot name="modalHead"></slot>
                    </h3>
                </div>
                <div class="modal-body col-xs-12 row">
                    <slot name="modalBody"></slot>
                </div>
                <div class="modal-footer text-right col-xs-12 row">
                    <slot name="modalFooter"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        props: ['show'],
        methods: {
            close: function () {
                this.$emit('close');
            },
            savePost: function () {
                // Some save logic goes here...

                this.close();
            }
        }
    }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }

    .modal-mask {
        position: fixed;
        z-index: 2000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        transition: opacity .3s ease;
    }

    .modal-container {
        width: 900px;
        height: 100vh;
        overflow: auto;
        padding: 20px 30px;
        background-color: #fff;
        position: fixed;
        right: 0;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    .text-right {
        text-align: right;
    }

    .form-label {
        display: block;
        margin-bottom: 1em;
    }

    .form-label > .form-control {
        margin-top: 0.5em;
    }

    .form-control {
        display: block;
        width: 100%;
        padding: 0.5em 1em;
        line-height: 1.5;
        border: 1px solid #ddd;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
</style>