<template>
    <div>
        <modal :show="show" @close="close()">
            <template #modalHead>
                <div>
                    Demande de session de <strong>{{demande.prenom +" "+demande.nom}}</strong>
                </div>
            </template>
            <template #modalBody>
                <div>
                    <div class="card-box">

                        <ul class="nav nav-tabs navtab-bg nav-justified">
                            <li :class="{ active: infos === 1}">
                                <a @click="showInfos(1)" aria-expanded="true">
                                    <span class="visible-xs"><i class="fa fa-home"></i></span>
                                    <span class="hidden-xs">INFORMATIONS</span>
                                </a>
                            </li>
                            <li :class="{ active: infos != 1}">
                                <a  @click="showInfos(0)" aria-expanded="false">
                                    <span class="visible-xs"><i class="fa fa-user"></i></span>
                                    <span class="hidden-xs">ACTIVITES</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane" :class="{ active: infos === 1}">
                                <P>
                                    <strong> NOM: </strong>{{demande.nom_complet}} ({{demande.profession}} <span v-if="demande.name"> à   <strong><i>{{demande.name}}</i></strong></span>)
                                </P>
                                <P>
                                    <strong> TEL :</strong> {{demande.telephone}} <strong> - * -   EMAIL : </strong>{{demande.email}}
                                </P>
                                <P>
                                    <strong> NOMBRE DE PLACES :</strong>  ({{demande.nombre}})
                                </P>
                            </div>
                            <div class="tab-pane" :class="{ active: infos != 1}">

                                <div class="panel panel-color panel-primary" v-for="activite in  demande.activites">
                                    <div class="panel-heading p-2" >
                                        <h3 class="panel-title"><span>{{activite.user.name}}</span><span class="float-right">{{activite.created_at | formatedDate}}</span></h3>
                                        <p class="panel-sub-title font-13 text-muted">{{activite.activite}}</p>
                                    </div>
                                    <div class="panel-body px-4 py-2">
                                        <p v-if="activite.description"><span v-html="activite.description"></span></p>
                                        <p v-else class="text-center">AUCUNE DESCRIPTION</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </template>
            <template #modalFooter>
                <div>

                </div>
            </template>
        </modal>
    </div>
</template>
<script>
    import Modal from '@/Shared/Modal';
    import InputError from "@/Shared/InputError";
    import LoadingBtn from "@/Shared/LoadingBtn";
    export default {
        components: {
            Modal,
            InputError,
            LoadingBtn
        },
        data(){
            return{
                demande: '',
                infos: 1,
            }
        },
        props:{
            show : {
                required: true,
            },
            demandeToShow: {
                default: false,
            }
        },
        methods:{
            close(){
                this.$emit('close');
            },
            showInfos(infos,activites){
                this.infos = infos;
                this.activites = activites;
            }

        },
        watch: {
            demandeToShow() {
                this.demande = this.demandeToShow
            }

        },

        mounted() {
        }
    }
</script>