<template>
    <app-layout>
        <div>
            <div class="col-xs-12">
                <div class="page-title-box">
                    <h4 class="page-title">Catégories actualités</h4>
                    <ol class="breadcrumb p-0 m-0">
                        <li>
                            <inertia-link href="/admin/dashboard">Dashboard</inertia-link>
                        </li>
                        <li>
                            <inertia-link href="/admin/actualite">Actualites </inertia-link>
                        </li>
                        <li class="active">
                            Catégorie et Types
                        </li>
                    </ol>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="col-xs-12 col-lg-6 ">
                <div class="col-xs-12 m-b-3 row">
                    <span class="h3">Catégories d'actualité </span>
                    <button type="button" class="btn btn-purple waves-light waves-effect w-md m-b-5 pull-right" @click="ajouterCategorieMag()">Ajouter</button>

                    <div>

                        <datatable
                                :fetch-url="$page.props.app.url+'admin/categories-actualites'"
                                :columns="categorieMagColumns"
                                :color="'table-bordered-purple'"
                                :btns="btnColumns"
                                @modifier="editingCategorieMag($event)"
                                @supprimer="deletingCategorieMag($event)"
                                ref="categorieTable"
                        ></datatable>
                    </div>
                </div>
            </div>
            <div class="col-xs-<!--
                    <button type="button" class="btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right" @click="ajouterTypeMag()">Ajouter</button>
            -->
            12 col-lg-6 ">
                <div class="col-xs-12 m-b-3 row">
                    <span class="h3">Types d'actualité </span>

                    <div>

                        <datatable
                                :fetch-url="$page.props.app.url+'admin/types-actualites'"
                                :columns="typeColumns"
                                :color="'table-bordered-inverse'"
                                :btns="btnColumns"
                                @modifier="editingTypesMag($event)"
                                @supprimer="deletingTywpesMag($event)"
                                ref="typeTable"
                        ></datatable>
                    </div>
                </div>
            </div>
            <categorie-mag-form :show="showCategorieMagForm" :editMode="editCategorieMag" :categorieMagToEdit="categorieMagToEdit" @close="close"  @updated="reloadCategorieMag()" ref="categorieMagForm"></categorie-mag-form>
            <type-mag-form :show="showTypeMagForm" :editMode="editTypeMag" :typeMagToEdit="typeMagToEdit" @close="close"  @updated="reloadTypeMag()"></type-mag-form>
        </div>
    </app-layout>
</template>

<script>
    import AppLayout from "../../../Layout/AppLayout";
    import CategorieMagForm from "@/Pages/Magazine/Categorie/CategorieMagForm";
    import TypeMagForm from "@/Pages/Magazine/Categorie/TypeMagForm";
    import Datatable from "@/Shared/Datatable";
    export default {
        components:{
            AppLayout,
            CategorieMagForm,
            TypeMagForm,
            Datatable
        },
        props: ['data'],
        data() {
            return {
                show: false,
                showTypeMagForm:false,
                editTypeMag: false,
                typeMagToEdit: '',
                showCategorieMagForm:false,
                editCategorieMag: false,
                categorieMagToEdit: '',
                typeColumns:[{name: 'id', titre: '#ID'},{name: 'fr_libelle', titre: 'LIBEELLE FR'},
                    {name: 'en_libelle', titre: 'LIBEELLE EN'}],
                categorieMagColumns:[{name: 'id', titre: '#ID'},{name: 'fr_libelle', titre: 'libelle_categorie'},
                    {name: 'en_libelle', titre: 'libelle_categorie'}],
                btnColumns:[
                    {name: 'Editer', action: 'edit', classes: 'btn btn-xs btn-warning'},
                    {name: 'Supprimer', action: 'delete', classes: 'btn btn-xs btn-danger'}],
            }
        },
        methods: {
            close(){
                this.$emit('close');
                this.show = false;
                this.showTypeMagForm = false;
                this.showCategorieMagForm = false;
            },
            reloadTypeMag(){
                this.$refs.typeTable.reloadData();
            },
            reloadCategorieMag(){
                this.$refs.categorieTable.reloadData();
            },
            loadDomaines(){
                /*axios.get('/admin/domaines/all')
                    .then(response => {
                        this.domaines = response.data;
                        //tail.select(".tail-select", { /!* Your Options *!/ });
                    });*/
            },

            editingTypesMag(data){
                this.typeMagToEdit = data;
                this.editTypeMag = true;
                this.showTypeMagForm = true;
            },
            ajouterTypeMag(){
                this.showTypeMagForm = true;
            },
            editingCategorieMag(data){
                this.categorieMagToEdit = data;
                this.editCategorieMag = true;
                this.showCategorieMagForm = true;
            },
            ajouterCategorieMag(){
                this.showCategorieMagForm = true;
            },
            deletingTypesMag (data){
                Swal.fire({
                    title: 'Êtes-vous sûr?',
                    text: "Vous ne pourrez plus revenir en arrière!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui, supprimez-le!'
                }).then((result) => {
                    if(result.value){
                        data._method = 'DELETE';

                        this.$inertia.post('/admin/type-actualite/'+data.id, data,{
                            onSuccess: (page) => {
                                this.reloadTypeMag();
                            },
                            onError: (page) => {

                            },
                            preserveScroll: true,
                        }).catch(() => {


                        });
                    }
                });
            },
            deletingCategorieMag (data){
                Swal.fire({
                    title: 'Êtes-vous sûr?',
                    text: "Vous ne pourrez plus revenir en arrière!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui, supprimez-le!'
                }).then((result) => {
                    if(result.value){
                        data._method = 'DELETE';

                        this.$inertia.post('/admin/categorie-actualite/'+data.id, data,{
                            onSuccess: (page) => {
                                this.reloadCategorieMag();
                            },
                            onError: (page) => {

                            },
                            preserveScroll: true,
                        }).catch(() => {


                        });
                    }
                });
            },

        },
        mounted() {
            //this.loadDomaines();
        }
    }
</script>

