<template>
    <div class="data-table ">
        <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="panel">
                            <div class="panel-heading">
                                <div class="row">
                                    <div class="col col-sm-3 col-xs-12">
                                        <!--<h4 class="title">Data <span>List</span></h4>-->
                                    </div>
                                    <div class=" col-xs-12  text-right">

                                        <div class="btn_group">
                                            <div class="input-group m-t-10">
                                                <input type="text" id="searchInput" placeholder="Rechercher" v-model="search" @keyup="fetchData()" class="form-control"  >

                                                <span class="input-group-btn">
                                                    <button type="button" class="btn waves-effect waves-light btn-primary btnsearch" @click="fetchData"><i class="fa fa-search"></i></button>
                                                </span>
                                            </div>
                                            <!--<button class="btn btn-default" title="Reload"><i class="fa fa-search"></i></button>
                                            <button class="btn btn-default" title="Pdf"><i class="fa fa-file-pdf"></i></button>
                                            <button class="btn btn-default" title="Excel"><i class="fas fa-file-excel"></i></button>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body table-responsive " id="style-2">
                                <table class="table">
                                    <thead>
                                    <tr>
                                       <th v-for="column in columns" :key="column.name" @click="sortByColumn(column.name)"  class="table-head sort">
                                            {{ column | columnHead }}
                                            <span v-if="column.name === sortedColumn">
                                                <i v-if="order === 'asc' " class="fa fa-arrow-up"></i>
                                                <i v-else class="fa fa-arrow-down"></i>
                                            </span>
                                        </th>
                                        <th v-for="(link, keyx) in links" class="justify-content-center">
                                            <span>{{link.name}}</span>
                                        </th>

                                        <th v-for="(btn, keyx) in btns" class="justify-content-center">
                                            <span v-html="btn.name"></span>
                                        </th>
                                        </tr>
                                </thead>
                                <tbody>
                                    <tr class="" v-if="tableData.length === 0">
                                        <td class="lead text-center" :colspan="columns.length + 1+btns.length">No data found.</td>
                                    </tr>
                                    <tr v-for="(data, key1) in tableData" :key="data.id" class="m-datatable__row" v-else>
                                        <td v-for="(column, key2) in columns">
                                            <!--<span v-html="$options.filters.str_limit(data[column.name],column.size) " v-if="column.type !='date'"></span>-->
                                            <span class="whitespace-no-wrap" v-html="$options.filters.highlight(data[column.name],column.size) " v-if="column.type !='date'"></span>

                                            <span v-if="column.type =='date'">{{data[column.name] | formatedDate}}</span>
                                        </td>
                                        <td v-for="(link, keyl) in links">
                                            <inertia-link :class="link.classes" :href="link.url+data.hashed_id" >{{link.name}}</inertia-link>
                                        </td>
                                        <td v-for="(btn, keyx) in btns">
                                            <button :class="btn.classes" v-if="btn.action == 'show'" @click="show(data)"> <i class="mdi mdi-eye"></i>
                                                {{btn.name}}</button>
                                            <button :class="btn.classes" v-if="btn.action == 'edit' && data.deleted_at ==null && data.etat !=1" @click="editer(data)"><i class="mdi mdi-pencil-box-outline"></i>
                                                {{btn.name}}</button>
                                            <button v-if="btn.action == 'edit' && data.deleted_at != null" class="btn btn-xs btn-inverse"> X Suppirmé</button>
                                            <button v-if="btn.action == 'edit' && data.deleted_at == null && data.etat == 1" class="btn btn-xs btn-inverse"> X Cloturée</button>

                                            <button :class="btn.classes" v-if="btn.action == 'delete' && data.deleted_at == null && data.etat != 1" @click="supprimer(data)"><i class="ion-ionic ion-trash-a"></i>
                                                {{btn.name}}</button>
                                            <button v-if="btn.action == 'delete' && data.deleted_at != null" class="btn btn-xs btn-inverse">X Suppirmé</button>
                                            <button v-if="btn.action == 'delete' && data.deleted_at == null && data.etat == 1" class="btn btn-xs btn-inverse">X Cloturée</button>

                                            <button v-if="btn.action == 'avant' && data.deleted_at == null && data.en_avant == 0" class="btn btn-xs btn-success" @click="mettreEnAvant(data)">Mettre en Avant</button>
                                            <button v-if="btn.action == 'avant' && data.deleted_at == null && data.en_avant == 1" class="btn btn-xs btn-danger" @click="mettreEnAvant(data)"> X Annuler</button>

                                        </td>
                                    </tr>   
                                </tbody>
                                </table>
                            </div>
                            <div class="panel-footer">
                                <div class="row" v-if="pagination && tableData.length > 0">
                                    <div class="col col-xs-12">Affichage de  {{ pagination.data.length }} sur {{ pagination.meta.total }}  lignes</div>
                                    <div class="col-xs-12">
                                        <ul class="pagination hidden-xs pull-right">
                                            <li :class="{'disabled' : currentPage === 1}">
                                                <a @click.prevent="changePage(currentPage - 1)" href="#"><</a>
                                            </li>
                                            <li v-for="page in pagesNumber" :class="{'active': page == pagination.meta.current_page}">
                                                <a href="javascript:void(0)" @click.prevent="changePage(page)" >{{page}}</a>
                                            </li>

                                            <li :class="{'disabled': currentPage === pagination.meta.last_page }">
                                                <a @click.prevent="changePage(currentPage + 1)"  href="#">></a>
                                            </li>
                                        </ul>
                                        <ul class="pagination visible-xs pull-right">
                                            <li :class="{'disabled' : currentPage === 1}">
                                                <a @click.prevent="changePage(currentPage - 1)" href="#"><</a>
                                            </li>
                                            <li :class="{'disabled': currentPage === pagination.meta.last_page }">
                                                <a @click.prevent="changePage(currentPage + 1)" href="#">></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>


    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        props: {
            fetchUrl: { type: String, required: true },
            columns: { type: Array, required: true },
            btns: { type: Array, required: true },
            links: { type: Array, required: false },
            color: {type: String, default: 'table-bordered-purple'},
            noShort : { type: Array, required: false },

    },
        data() {
            return {
                tableData: [],
                url: '',
                search: '',
                pagination: {
                    meta: { to: 1, from: 1 }
                },
                offset: 4,
                currentPage: 1,
                perPage: 10,
                sortedColumn: this.columns[0].name,
                order: 'desc'
            }
        },
        watch: {
            fetchUrl: {
                handler: function(fetchUrl) {
                    this.url = fetchUrl
                },
                immediate: true
            },

        },
        mounted() {
            return this.fetchData()
        },
        computed: {
            /**
             * Get the pages number array for displaying in the pagination.
             * */
            pagesNumber() {
                if (!this.pagination.meta.to) {
                    return []
                }
                let from = this.pagination.meta.current_page - this.offset
                if (from < 1) {
                    from = 1
                }
                let to = from + (this.offset * 2)
                if (to >= this.pagination.meta.last_page) {
                    to = this.pagination.meta.last_page
                }
                let pagesArray = []
                for (let page = from; page <= to; page++) {
                    pagesArray.push(page)
                }
                return pagesArray
            },
            /**
             * Get the total data displayed in the current page.
             * */
            totalData() {
                return (this.pagination.meta.to - this.pagination.meta.from) + 1
            }
        },
        methods: {
            fetchData() {
                let dataFetchUrl = `${this.url}?page=${this.currentPage}&column=${this.sortedColumn}&order=${this.order}&per_page=${this.perPage}&search=${this.search}`
                axios.get(dataFetchUrl)
                    .then(({ data }) => {
                        this.pagination = data;
                        this.tableData = data.data;
                    }).catch(error => this.tableData = [])
            },

            str_limit(value,size) {
                if (!value) return '';
                value = value.toString();

                if (value.length <= size || !size  ) {
                    return value;
                }
                return value.substr(0, size) + '...';
            },
            show(row){
                this.$emit('show', row);
            },
            editer(row){
                this.$emit('modifier', row);
            },
            supprimer(row){
                this.$emit('supprimer', row);
            },
            mettreEnAvant(row){
                this.$emit('mettreenavant', row);
            },
            delete(row){
                this.$emit('delete', row);
            },
            reloadData(){
                this.fetchData();
            },
            serialNumber(key) {
                return (this.currentPage - 1) * this.perPage + 1 + key
            },
            /**
             * Change the page.
             * @param pageNumber
             */
            changePage(pageNumber) {
                if (pageNumber>=1 && pageNumber<=this.pagination.meta.last_page ){
                    this.currentPage = pageNumber
                    this.fetchData()
                }
            },
            /**
             * Sort the data by column.
             * */
            sortByColumn(column) {
                    if (column === this.sortedColumn) {
                        this.order = (this.order === 'asc') ? 'desc' : 'asc'
                    } else {
                        this.sortedColumn = column
                        this.order = 'desc'
                    }
                    this.fetchData()
            }
        },
        filters: {
            columnHead(value) {
                if(value.title){
                    return value.title.toUpperCase()

                }
                return value.name.split('_').join(' ').toUpperCase()
            },
            /*str_limit(value,size) {
                if (!value) return '';
                value = value.toString();

                if (value.length <= size || !size  ) {
                    return value;
                }
                return value.substr(0, size) + '...';
            },*/
            highlight(words, size){
                let resized = '';
                if(!size){
                    size = 55;
                }
                if (!words) resized =  '';
                    words = words.toString();

                if (words.length <= size || !size  ) {
                    resized = words;
                }else{
                    resized = words.substr(0, size) + '...';
                }
                let search = document.getElementById('searchInput').value.replace(/[^\wèéòàùì\s]/gi, '');
                if (search !==""){
                    let iQuery = new RegExp(search, "ig");

                    return resized.toString().replace(iQuery, function(matchedTxt,a,b){
                        return ('<span class=\'highlight\'>' + matchedTxt + '</span>');
                    });
                }else{
                    return  resized;
                }


            },
            formatedssDate(date) {
                return moment(date).format(' Do MMMM YYYY');
            }
        },
        name: 'DataTable'
    }
</script>
<style scoped>
    .highlight{
        background-color: #ffff00;
        color: black !important;
    }
    .sort{
        cursor: pointer;
    }
    .fa-arrow-down,.fa-arrow-up{
        color: #0d0d0f !important;
    }
    .panel{
        background: linear-gradient(to right, #2980b9, #2c3e50);
        padding: 0;
        border-radius: 10px;
        border: none;
        box-shadow: 0 0 0 5px rgba(0,0,0,0.05), 0 0 0 10px rgba(0,0,0,0.05);
    }
    .data-table{
        margin-top: 50px;
    }
    .panel .panel-heading{
        padding: 20px 15px;
        border-radius: 10px 10px 0 0;
        margin:0;
    }
    .panel .panel-heading .title{
        color: #fff;
        font-size: 28px;
        font-weight: 500;
        text-transform: capitalize;
        line-height: 40px;
        margin: 0;
    }
    .panel .panel-heading .btn{
        color: rgba(255, 255,255,0.5);
        background: transparent;
        font-size: 16px;
        text-transform: capitalize;
        border: 2px solid #fff !important;
        /*border-radius: 50px;*/
        transition: all 0.3s ease 0s;
    }
    .panel .panel-heading .btn:hover{
        color: #fff;
        text-shadow: 3px 3px rgba(255,255,255,0.2);
    }
    .panel .panel-heading .form-control{
        color: #fff;
        background-color: transparent;
        width: 100%;
        height: 40px;
        border: 2px solid #fff;
        display: inline-block;
        transition: all 0.3s ease 0s;
    }
    .panel .panel-heading .form-control:focus{
        background-color: rgba(255,255,255,0.2);
        box-shadow: none;
        outline: none;
    }
    .panel .panel-heading .form-control::placeholder{
        color: rgba(255,255,255,0.5);
        font-size: 15px;
        font-weight: 500;
    }
    .btnsearch{
        padding: 7px 17px;
    }
    .panel .panel-body{
        padding: 0;
    }
    #style-2::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 5px;
        background-color: #F5F5F5;
    }

    #style-2::-webkit-scrollbar
    {
        width: 12px;
        background-color: #F5F5F5;
    }

    #style-2::-webkit-scrollbar-thumb
    {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #0c3231;
    }
    .panel .panel-body .table thead tr th {
        color: #fff;
        background-color: rgba(255,255,255,0.2);
        font-size: 12px;
        font-weight: 500;
        text-transform:uppercase;
        padding: 12px 10px;
        border: none;
        white-space: nowrap;

    }
    .panel .panel-body .table tbody tr td {
        color: #fff;
        font-size: 12px;
        padding: 12px 10px;
        vertical-align: middle;
        border: none;
    }
    .btn-xs{
        font-size: 12px !important;
    }
    .panel .panel-body .table tbody tr:nth-child(even){background-color: rgba(255,255,255, 0.05);}
    .panel .panel-body .table tbody .action-list{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .panel .panel-body .table tbody .action-list li{
        display: inline-block;
        margin: 0 5px;
    }
    .panel .panel-body .table tbody .action-list li a {
        color: #fff;
        font-size: 15px;
        position: relative;
        z-index: 1;
        transition: all 0.3s ease 0s;
    }

    .panel .panel-body .table tbody .action-list li a:hover{
        text-shadow: 3px 3px 0 rgba(255,255,255,0.3);

    }
    .panel .panel-body .table tbody .action-list li a:before,
    .panel .panel-body .table tbody .action-list li a:after {
        content: attr(data-tip);
        color: #fff;
        background-color: #111;
        font-size: 12px;
        padding: 5px 7px;
        border-radius: 4px;
        text-transform: capitalize;
        display: none;
        transform: translateX(-50%);
        position: absolute;
        left: 50%;
        top:-32px;
        transition: all 0.3s ease 0s;
    }
    .panel .panel-body .table tbody .action-list li a:after {
        content: '';
        height: 15px;
        width: 15px;
        padding: 0;
        border-radius: 0;
        transform: translateX(-50%) rotate(45deg);
        top: -18px;
        z-index: -1;
    }
    .panel .panel-body .table tbody .action-list li a:hover:before,
    .panel .panel-body .table tbody .action-list li a:hover:after {
        display: block;
    }
    .panel .panel-footer {
        color: #fff;
        background-color: transparent;
        padding: 15px;
        border:none;
    }
    .panel .panel-footer .col {line-height: 35px;}
    .pagination{margin: 0;}
    .pagination li a{
        color: #fff;
        background-color: transparent;
        border:2px solid transparent;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        line-height: 21px;
        width: 25px;
        height: 25px;
        padding: 0;
        margin: 0 3px;
        border-radius: 50px;
        transition: all 0.3s ease 0s;
    }
    .pagination li a:hover{
        color:#fff;
        background-color: transparent;
        border-color: rgba(255,255,255,0.2);
    }
    .pagination li a:focus,
    .pagination li.active a,
    .pagination li.active a:hover{
        color:#fff;
        background-color: transparent;
        border-color:#fff;
    }
    .pagination li:first-child a,
    .pagination li:last-child a{
        border-radius: 50%;
    }
    @media only screen and (max-width: 767px) {
        .panel .panel-heading .title{
            text-align: center;
            margin: 0 0 10px;
        }
        .panel .panel-heading .btn_group{text-align: center}
    }

</style>