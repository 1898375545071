<template>
    <div>
        <modal :show="show" @close="close()">
            <template #modalHead>
                <div>
                    Suivi demande de recrutement
                </div>
            </template>
            <template #modalBody>
                <div>
                    <form class="form-horizontal">
                        <div class="form-group">
                            <label class="col-xs-12 ">Quelle activité avez vous fait ?</label>
                            <div class="col-xs-12">
                                <input type="text" v-model="form.activite" class="form-control" placeholder="Activité menée">
                                <input-error :formName="'addActiviteInt'" :message="'activite'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Description</label>
                            <div class="col-xs-12">
                                <ckeditor v-model="form.description" :config="editorConfig"></ckeditor>
                                <input-error :formName="'addActiviteInt'" :message="'description'" class="mt-2" />
                            </div>
                        </div>
                    </form>
                </div>
            </template>
            <template #modalFooter>
                <div>
                    <loading-btn  @click="addActiviteInt" ref="submitButton" :classes="'btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right'" >Ajouter</loading-btn>
                </div>
            </template>
        </modal>
    </div>
</template>
<script>
    import Modal from '@/Shared/Modal';
    import InputError from "@/Shared/InputError";
    import LoadingBtn from "@/Shared/LoadingBtn";
    export default {
        components: {
            Modal,
            InputError,
            LoadingBtn
        },
        data(){
            return{
                editorConfig: {
                    language: 'fr',
                    //uiColor: '#9AB8F3',
                    height: 100
                },
                form: this.$inertia.form({
                    activite : '',
                    description : '',
                    demande_id : '',
                }, {
                    bag: 'addActiviteInt',

                })
            }
        },
        props:{
            show : {
                required: true,
            },
            recrutementToEdit : {
                required: true,
            },
        },
        methods:{
            close(){
                this.form.reset();
                this.$emit('close');
            },
            addActiviteInt(){
                this.$inertia.post('/admin/suivi-recrutement', this.form, {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => {
                        this.$refs.submitButton.stopLoading();
                    },
                    onSuccess: (page) => {
                        if (page.props.errors.addActiviteInt) {
                            return;
                        }
                       // this.$emit('updated');
                        this.form.reset()

                        this.close();
                    },
                    preserveScroll: true,
                });
            },
        },
        watch: {
            recrutementToEdit() {
                this.form.demande_id = this.recrutementToEdit.id
            }
        },
        mounted() {
        }
    }
</script>