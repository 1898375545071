<template>
    <div>
        <modal :show="show" @close="close()">
            <template #modalHead>
                <div>
                    Nouvelle session de formation
                </div>
            </template>
            <template #modalBody>
                <form class="form-horizontal">
                    <div class="col-xs-12 row">
                        <div class="form-group col-xs-12">
                            <label class="col-xs-12 ">Date de début</label>
                            <div class="col-xs-12">
                                <date-picker valueType="format" :lang="'fr'" v-model="form.date_debut" />
                                <input-error :formName="'addSession'" :message="'date_debut'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group col-xs-12">
                            <label class="col-xs-12 ">Formation </label>
                            <div class="col-xs-12">
                                <v-select  placeholder="Selectionnez une formation" label="fr_titre_formation" :options="formations"
                                           :reduce="formation => formation.id" v-model="form.formation_id" ></v-select>

                                <input-error :formName="'addSession'" :message="'formation_id'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group col-xs-12 col-lg-6 ">
                            <label class="col-xs-12">Nombre de places</label>
                            <div class="col-xs-12">
                                <input type="number" placeholder="Nombre de places" v-model="form.nombre_place" class="form-control">
                                <input-error :formName="'addSession'" :message="'nombre_place'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group col-xs-12 col-lg-6">
                            <label class="col-xs-12 ">Tarification en F CFA</label>
                            <div class="col-xs-12">
                                <input type="number" placeholder="Tarif de la formation" v-model="form.tarif" class="form-control">
                                <input-error :formName="'addSession'" :message="'tarif'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group col-xs-12 col-lg-6">
                            <div class="form-inline align-items-center"  >

                                <div class="mr-4 ">
                                    <label for="with_certif" >Avec Certfification ?</label>
                                </div>
                                <div>
                                    <input type="checkbox"  v-model="form.with_certif" id="with_certif" data-switch="bool">
                                    <label for="with_certif" data-on-label="Oui" data-off-label="Non"></label>
                                </div>
                                <input-error :formName="'addFormation'" :message="'with_certif'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group col-xs-12 col-lg-6">
                            <div class="form-inline align-items-center"  >
                                <div class="mr-4 ">
                                    <label for="presentiel" >Formation en présentiel ?</label>
                                </div>
                                <div>
                                    <input type="checkbox"  v-model="form.presentiel" id="presentiel" data-switch="bool">
                                    <label for="presentiel" data-on-label="Oui" data-off-label="Non"></label>
                                </div>
                                <input-error :formName="'addFormation'" :message="'presentiel'" class="mt-2" />
                            </div>
                        </div>
                    </div>
                </form>
            </template>
            <template #modalFooter>
                <div>

                    <loading-btn v-if="!editMode" @click="addSession" ref="submitButton" :classes="'btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right'" >Ajouter</loading-btn>
                    <loading-btn v-if="editMode" @click="updateDomaine" ref="submitButton" :classes="'btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right'" >Enregistrer</loading-btn>
                </div>
            </template>
        </modal>
    </div>
</template>
<script>
    import Modal from '@/Shared/Modal';
    import InputError from "@/Shared/InputError";
    import LoadingBtn from "@/Shared/LoadingBtn";
    export default {
        components: {
            Modal,
            InputError,
            LoadingBtn
        },
        data(){
            return{
                formations:[],
                form: this.$inertia.form({
                    date_debut : '',
                    nombre_place : '',
                    tarif : '',
                    with_certif : false,
                    presentiel : false,
                    etat : '',
                    formation_id : '',
                }, {
                    bag: 'addSession',

                })
            }
        },
        props:{
            show : {
                required: true,
            },
            editMode: {
                default: false,
            },
            sessionToEdit: {
                default: false,
            }
        },
        methods:{
            close(){
                this.form.reset();
                this.$emit('close');
            },
            booleanValue(value){
                let index = value.indexOf("Oui");
                if(index !== -1){
                    return true;
                }
                return false;
            },
            loadFormations(){
                axios.get('/admin/formations/all')
                    .then(response => {
                        this.formations = response.data;
                    });
            },
            addSession(){
                this.$inertia.post('/admin/session-formation', this.form, {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => {
                        this.$refs.submitButton.stopLoading();
                    },
                    onSuccess: (page) => {
                        if (page.props.errors.addSession) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()

                        this.close();
                    },
                    preserveScroll: true,
                });
            },
            updateDomaine(){
                this.form._method = 'PUT';
                this.$inertia.post('/admin/session-formation/'+this.sessionToEdit.id, this.form , {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => this.$refs.submitButton.stopLoading(),
                    onSuccess: (page) => {
                        if (page.props.errors.addSession) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()
                        this.close();
                    },
                    preserveScroll: true

                })
            }
        },
        watch: {
            sessionToEdit() {
                this.form.date_debut = this.sessionToEdit.date_debut;
                this.form.nombre_place = this.sessionToEdit.nombre_place;
                this.form.tarif = this.sessionToEdit.tarif;
                this.form.formation_id = this.sessionToEdit.formation_id;
                this.form.with_certif = this.booleanValue(this.sessionToEdit.with_certif);
                this.form.presentiel = this.booleanValue(this.sessionToEdit.presentiel);
            }
        },

        mounted() {
            this.loadFormations();
        }
    }
</script>