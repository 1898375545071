<template>
    <app-layout>
        <div>
            <div class="col-xs-12">
                <div class="page-title-box">
                    <h4 class="page-title">Catégories Formation</h4>
                    <ol class="breadcrumb p-0 m-0">
                        <li>
                            <inertia-link href="/admin/dashboard">Dashboard</inertia-link>
                        </li>
                        <li>
                            <inertia-link href="/admin/formation">Formations </inertia-link>
                        </li>
                        <li class="active">
                            Catégorie
                        </li>
                    </ol>
                    <div class="clearfix"></div>
                </div>
            </div>

            <div class="col-xs-12 ">
                <div class="col-xs-12 m-b-3 row">
                    <span class="h3">Programmes de la formation </span>
                    <button type="button" class="btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right" @click="ajouterProgramme()">Ajouter</button>

                    <div>


                    </div>
                </div>
                <div class="col-xs-12 m-t-5 row" v-for="programme in formation.programmes ">
                    <div class="portlet" >
                        <div @click="collapsing('bg-teal'+programme.id, 'icone'+programme.id)" style="cursor: pointer" class="portlet-heading bg-teal">
                            <h3 class="portlet-title">
                                {{programme.fr_titre_programme}}
                            </h3>
                            <div  class="portlet-widgets">
                                <span class="divider"></span>
                                <a ><i :id="'icone'+programme.id" class="ion-plus-round"></i></a>
                                <span class="divider"></span>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div :id="'bg-teal'+programme.id" class="panel-collapse collapse">
                            <div class="portlet-body px-0 ">
                                <div class="px-0">
                                    <ul class="nav nav-tabs navtab-bg nav-justified">
                                        <li :class="{ active: francais === 1}">
                                            <a @click="showfrancais(1)" aria-expanded="true">
                                                <span class="visible-xs"><i class="fa fa-home"></i></span>
                                                <span class="hidden-xs">Français</span>
                                            </a>
                                        </li>
                                        <li :class="{ active: francais != 1}">
                                            <a  @click="showfrancais(0)" aria-expanded="false">
                                                <span class="visible-xs"><i class="fa fa-user"></i></span>
                                                <span class="hidden-xs">Anglais</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content p-5">
                                        <div class="tab-pane" :class="{ active: francais === 1}">
                                            <P>
                                            <div>
                                                <h4>
                                                    <u>Titre</u> :
                                                </h4>
                                                {{programme.fr_titre_programme}}
                                            </div>
                                            <div>
                                                <h4>
                                                    <u>Contenu</u> :
                                                </h4>
                                                <span v-html="programme.fr_contenu"></span>

                                            </div>
                                            </P>

                                        </div>
                                        <div class="tab-pane" :class="{ active: francais != 1}">
                                            <div>
                                                <h4>
                                                    <u>Title</u> :
                                                </h4>
                                                {{programme.en_titre_programme}}
                                            </div>
                                            <div>
                                                <h4>
                                                    <u>Content</u> :
                                                </h4>
                                                <span v-html="programme.en_contenu"></span>
                                            </div>
                                        </div>
                                        <div>
                                            <button class="btn btn-warning btn-block" @click="editerProgramme(programme)">MODIFIER</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <programme-form :formation="formation" :show="showProgrammeForm" :editMode="editProgramme" :programmeToEdit="programmeToEdit" @close="close"  @updated="reloadProgrammes()" ref="categorieForm"></programme-form>
    </app-layout>
</template>

<script>
    import AppLayout from "../../Layout/AppLayout";
    import InputError from "@/Shared/InputError";
    import LoadingBtn from "@/Shared/LoadingBtn";
    import ProgrammeForm from "@/Pages/Formation/ProgrammeForm";
    export default {
        components:{
            AppLayout,
            InputError,
            LoadingBtn,
            ProgrammeForm
        },
        props: ['formation'],
        data() {
            return {
                editorConfig: {
                    language: 'fr',
                    height: 500
                },
                showProgrammeForm: false,
                editProgramme: false,
                programmeToEdit:'',
                francais: 1,
                form: this.$inertia.form({
                  //  titre_programme : '',
                    fr_contenu: '',
                    en_contenu: '',
                    formation_id: this.formation.id
                }, {
                    bag: 'addProgramme',

                })
            }
        },
        methods: {
            ajouterProgramme(){
                this.showProgrammeForm = true;
            },
            editerProgramme(programme){
                this.editProgramme = true;
                this.programmeToEdit = programme;
                this.showProgrammeForm = true;
            },
            close(){
                this.showProgrammeForm = false;
            },
            showfrancais(francais,activites){
                this.francais = francais;
                this.activites = activites;
            },
            collapsing(divId,iconeID){
                let div = document.getElementById(divId);
                let alldiv = document.getElementsByClassName("panel-collapse collapse in");
                let allicon = document.getElementsByClassName("ion-minus-round");
                let icone = document.getElementById(iconeID);
                for (var i = 0; i < alldiv.length; ++i) {
                    alldiv[i].classList.remove("in");
                    allicon[i].classList.replace( 'ion-minus-round','ion-plus-round');
                }
                if (div.classList.contains("in")){
                    div.classList.remove("in");
                    icone.classList.replace('ion-minus-round', 'ion-plus-round');
                }else{
                    div.classList.add("in");
                    icone.classList.replace( 'ion-plus-round','ion-minus-round');
                }
            },
            reloadProgrammes(){
                location.reload();

            },
            addProgramme(){
                this.$inertia.post('/admin/save-programme', this.form, {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => {
                        this.$refs.submitButton.stopLoading();
                    },
                    onSuccess: (page) => {
                        if (page.props.errors.addProgramme) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()

                        this.close();
                    },
                    preserveScroll: true,
                });
            },
            updateProgramme(){
                this.form._method = 'PUT';

                this.$inertia.post('/admin/programme/'+this.formation.programme.id, this.form , {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => this.$refs.submitButton.stopLoading(),
                    onSuccess: (page) => {
                        if (page.props.errors.addProgramme) {
                            return;
                        }
                        this.$emit('updated');
                        this.close();
                    },
                    preserveScroll: true

                })
            }

        },
        mounted() {

        }
    }
</script>

