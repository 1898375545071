<template>
    <div>
        <modal :show="show" @close="close()">
            <template #modalHead>
                <div>
                    Ajout d'un domaine de formation
                </div>
            </template>
            <template #modalBody>
                <div>
                    <form class="form-horizontal">
                        <div class="form-group">
                            <label class="col-xs-12 ">Libelle du domaine (Français)</label>
                            <div class="col-xs-12">
                                <input type="text" v-model="form.fr_libelle_domaine" class="form-control" value="Some text value...">
                                <input-error :formName="'addDomaine'" :message="'fr_libelle_domaine'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Libelle du domaine (Anglais)</label>
                            <div class="col-xs-12">
                                <input type="text" v-model="form.en_libelle_domaine" class="form-control" value="Some text value...">
                                <input-error :formName="'addDomaine'" :message="'en_libelle_domaine'" class="mt-2" />
                            </div>
                        </div>
                    </form>
                </div>
            </template>
            <template #modalFooter>
                <div>
                    <loading-btn v-if="!editMode" @click="addDomaine" ref="submitButton" :classes="'btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right'" >Ajouter</loading-btn>
                    <loading-btn v-if="editMode" @click="updateDomaine" ref="submitButton" :classes="'btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right'" >Enregistrer</loading-btn>
                </div>
            </template>
        </modal>
    </div>
</template>
<script>
    import Modal from '@/Shared/Modal';
    import InputError from "@/Shared/InputError";
    import LoadingBtn from "@/Shared/LoadingBtn";
    export default {
        components: {
            Modal,
            InputError,
            LoadingBtn
        },
        data(){
            return{
                form: this.$inertia.form({
                    fr_libelle_domaine : '',
                    en_libelle_domaine : '',
                }, {
                    bag: 'addDomaine',

                })
            }
        },
        props:{
            show : {
                required: true,
            },
            editMode: {
                default: false,
            },
            domaineToEdit: {
                default: false,
            }
        },
        methods:{
            close(){
                this.form.reset();
                this.$emit('close');
            },
            addDomaine(){
                this.$inertia.post('/admin/domaine', this.form, {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => {
                        this.$refs.submitButton.stopLoading();
                    },
                    onSuccess: (page) => {
                        if (page.props.errors.addDomaine) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()

                        this.close();
                    },
                    preserveScroll: true,
                });
            },
            updateDomaine(){
                this.form._method = 'PUT';

                this.$inertia.post('/admin/domaine/'+this.domaineToEdit.id, this.form , {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => this.$refs.submitButton.stopLoading(),
                    onSuccess: (page) => {
                        if (page.props.errors.addDomaine) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()
                        this.close();
                    },
                    preserveScroll: true

                })
            }
        },
        watch: {
            domaineToEdit() {
                this.form.fr_libelle_domaine = this.domaineToEdit.fr_libelle_domaine,
                this.form.en_libelle_domaine = this.domaineToEdit.en_libelle_domaine
            }
        },

        mounted() {
            console.log(this.error)
        }
    }
</script>