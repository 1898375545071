<template>
    <div id="wrapper">

        <top-bar>

        </top-bar>
        <side-menu>

        </side-menu>
        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="content-page">
            <!-- Start content -->
            <div class="content">
                <div class="container">


                    <div class="row">

                        <main>
                            <slot></slot>
                        </main>
                    </div>



                </div> <!-- container -->

            </div> <!-- content -->

            <footer class="footer text-right">
                2021 © GBG-SI.
            </footer>

        </div>


        <!-- ============================================================== -->
        <!-- End Right content here -->
        <!-- ============================================================== -->



    </div>
</template>
<script>
    import TopBar from "./Templates/TopBar";
    import SideMenu from "./Templates/SideBar";
    import { Inertia } from '@inertiajs/inertia'
    export default {
        components:{
            TopBar,
            SideMenu
        },
        mounted() {
            Inertia.on('finish', () => {
                let mess = this.$page.props.flash.message;
                if (mess) {
                    this.$swal.fire({
                        icon: mess.type,
                        title: mess.text,
                        toast: true,
                        target: document.querySelector('#myDiv'),
                        position: 'top-end',
                        showConfirmButton: false,
                        showCloseButton: true,
                        timerProgressBar: true,
                        timer: 5000
                    })
                }

            })

        }

    }
</script>
<style src="./../../../node_modules/sweetalert2/dist/sweetalert2.css">

</style>