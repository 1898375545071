<template>
    <app-layout>
        <div>
            <div class="col-xs-12">
                <div class="page-title-box">
                    <h4 class="page-title">Catégories Formation</h4>
                    <ol class="breadcrumb p-0 m-0">
                        <li>
                            <inertia-link href="/admin/dashboard">Dashboard</inertia-link>
                        </li>
                        <li>
                            <inertia-link href="/admin/actualites">Actualités </inertia-link>
                        </li>
                        <li class="active">
                            Ajout actualité
                        </li>
                    </ol>
                    <div class="clearfix"></div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="card-box">
                    <div class="row">
                        <div class="col-xs-12">
                            <h4 class="m-t-0 header-title">Création d'un nouvel article dans le magazine de GBG</h4>
                            <form class="form-horizontal">
                                <div class="form-group">
                                    <label class="col-xs-12 ">Titre de l'actualité (en français)</label>
                                    <div class="col-xs-12">
                                        <input type="text" v-model="form.fr_titre_mag" class="form-control">
                                        <input-error :formName="'addActualite'" :message="'fr_titre_mag'" class="mt-2" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-xs-12 ">Titre de l'actualité (en anglais)</label>
                                    <div class="col-xs-12">
                                        <input type="text" v-model="form.en_titre_mag" class="form-control">
                                        <input-error :formName="'addActualite'" :message="'en_titre_mag'" class="mt-2" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-xs-12 ">Catégorie </label>
                                    <div class="col-xs-12">
                                        <v-select  placeholder="Selectionnez une catégorie" label="fr_libelle" :options="categories"
                                                   :reduce="categorie => categorie.id" v-model="form.categorie_id" ></v-select>
                                        <input-error :formName="'addActualite'" :message="'duree'" class="mt-2" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-xs-12 ">Type d'actualité </label>
                                    <div class="col-xs-12">
                                        <v-select  placeholder="Selectionnez un type" label="fr_libelle" :options="types"
                                                   :reduce="type => type.id" v-model="form.type_id" ></v-select>
                                        <input-error :formName="'addActualite'" :message="'type_id'" class="mt-2" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-xs-12 ">Contenu de l'article (en français)</label>
                                    <div class="col-xs-12">
                                        <ckeditor v-model="form.fr_description" :config="editorConfig"></ckeditor>
                                        <input-error :formName="'addActualite'" :message="'fr_description'" class="mt-2" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-xs-12 ">Contenu de l'article (en anglais)</label>
                                    <div class="col-xs-12">
                                        <ckeditor v-model="form.en_description" :config="editorConfig"></ckeditor>
                                        <input-error :formName="'addActualite'" :message="'en_description'" class="mt-2" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-xs-12 ">Résumé de l'article (en français)</label>
                                    <div class="col-xs-12">
                                        <ckeditor v-model="form.apercu" :config="editorConfig2"></ckeditor>
                                        <input-error :formName="'addActualite'" :message="'apercu'" class="mt-2" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-xs-12 ">Résumé de l'article (en anglais)</label>
                                    <div class="col-xs-12">
                                        <ckeditor v-model="form.en_apercu" :config="editorConfig2"></ckeditor>
                                        <input-error :formName="'addActualite'" :message="'en_apercu'" class="mt-2" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div >
                                        <label class="control-label">Image illustrative (français)</label>
                                        <input type="file" ref="photo_fr" @change="updatePreview"  class="filestyle" data-buttonbefore="true" id="filestyle-8" tabindex="-1" style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
                                        <div class="bootstrap-filestyle input-group">
                                <span class="group-span-filestyle input-group-btn" tabindex="0">
                                    <label for="filestyle-8" class="btn btn-default ">
                                        <span class="icon-span-filestyle glyphicon glyphicon-folder-open"></span>
                                        <span class="buttonText">Choisir un fichier</span>
                                    </label>
                                </span>
                                            <input type="text" class="form-control " placeholder="" disabled="">
                                        </div>
                                    </div>
                                    <div v-show="form.fr_image" class="float-left">
                                          <span class="block w-20 h-20 rounded-full"
                                                :style="'width: 5rem; height: 5rem; border-radius: 9px; display: block; background-size: cover; background-repeat: no-repeat; background-position: center center; background-image: url(\'' + form.fr_image + '\');'">
                                          </span>
                                    </div>
                                    <div v-show="form.fr_image_old" class="float-left">
                                          <span class="block w-20 h-20 rounded-full"
                                                :style="'width: 5rem; height: 5rem; border-radius: 9px; display: block; background-size: cover; background-repeat: no-repeat; background-position: center center; background-image: url(\'../storage/images-actualite/' + form.fr_image_old + '\');'">
                                          </span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div >
                                        <label class="control-label">Image illustrative (en anglais)</label>
                                        <input type="file" ref="photo_en" @change="updatePreviewEng"  class="filestyle" data-buttonbefore="true" id="filestyle-9" tabindex="-1" style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
                                        <div class="bootstrap-filestyle input-group">
                                <span class="group-span-filestyle input-group-btn" tabindex="0">
                                    <label for="filestyle-9" class="btn btn-default ">
                                        <span class="icon-span-filestyle glyphicon glyphicon-folder-open"></span>
                                        <span class="buttonText">Choisir un fichier</span>
                                    </label>
                                </span>
                                            <input type="text" class="form-control " placeholder="" disabled="">
                                        </div>
                                    </div>
                                    <div v-show="form.en_image" class="float-left">
                                      <span class="block w-20 h-20 rounded-full"
                                            :style="'width: 5rem; height: 5rem; border-radius: 9px; display: block; background-size: cover; background-repeat: no-repeat; background-position: center center; background-image: url(\'' + form.en_image + '\');'">
                                      </span>
                                    </div>
                                    <div v-show="form.en_image_old" class="float-left">
                                          <span class="block w-20 h-20 rounded-full"
                                                :style="'width: 5rem; height: 5rem; border-radius: 9px; display: block; background-size: cover; background-repeat: no-repeat; background-position: center center; background-image: url(\'../storage/images-actualite/' + form.en_image_old + '\');'">
                                          </span>
                                    </div>
                                </div>
                            </form>

                            <div class="clearfix"></div>


                        </div>
                        <loading-btn v-if="actualite" @click="updateActualite" ref="submitButton" :classes="'btn btn-warning waves-light waves-effect w-md m-b-5 pull-right'" >Enregistrer</loading-btn>

                        <loading-btn v-else @click="addActualite" ref="submitButton" :classes="'btn btn-success waves-light waves-effect w-md m-b-5 pull-right'" >Ajouter</loading-btn>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
    import AppLayout from "../../Layout/AppLayout";
    import InputError from "@/Shared/InputError";
    import LoadingBtn from "@/Shared/LoadingBtn";
    export default {
        components:{
            AppLayout,
            InputError,
            LoadingBtn
        },
        props: ['actualite'],
        data() {
            return {
                editorConfig: {
                    language: 'fr',
                    height: 500
                },
                editorConfig2: {
                    language: 'fr',
                    height: 200
                },
                categories : [],
                types : [],
                form: this.$inertia.form({
                    //  fr_titre_mag : '',
                    fr_titre_mag: '',
                    en_titre_mag: '',
                    type_id: '',
                    categorie_id: '',
                    fr_description: '',
                    en_description: '',
                    apercu:'',
                    en_apercu:'',
                    fr_image: null,
                    en_image: null,
                }, {
                    bag: 'addActualite',

                })
            }
        },
        methods: {
            updatePreview() {
                const reader = new FileReader();
                if(this.$refs.photo_fr.files){
                    let size = this.$refs.photo_fr.files[0].size/1024;
                    let type = this.$refs.photo_fr.files[0].type.split('/').pop();

                    let accepted_formats = ['jpeg','jpg', 'png', 'webp'];

                    if (size>1024){
                        alert("Fichier lourd. ("+Math.floor(size/1024)+" MB) Compressez l'image  avant de le poster !");
                        return '';
                    }
                    if (!accepted_formats.includes(type)){

                        alert("ERREUR TYPE DE FICHIER ! Choississez un fichier de type : "+accepted_formats);
                        return '';
                    }
                    reader.onload = (e) => {
                        this.form.fr_image = e.target.result;
                    };

                    reader.readAsDataURL(this.$refs.photo_fr.files[0]);
                }
            },
            updatePreviewEng() {
                const reader = new FileReader();
                if(this.$refs.photo_en.files){
                    let size = this.$refs.photo_en.files[0].size/1024;
                    let type = this.$refs.photo_en.files[0].type.split('/').pop();

                    let accepted_formats = ['jpeg','jpg', 'png', 'webp'];

                    if (size>1024){
                        alert("Fichier lourd. ("+Math.floor(size/1024)+" MB) Compressez l'image  avant de le poster !");
                        return '';
                    }
                    if (!accepted_formats.includes(type)){

                        alert("ERREUR TYPE DE FICHIER ! Choississez un fichier de type : "+accepted_formats);
                        return '';
                    }
                    reader.onload = (e) => {
                        this.form.en_image = e.target.result;
                    };

                    reader.readAsDataURL(this.$refs.photo_en.files[0]);
                }
            },
            updateActualite(){
                this.form._method = 'PUT';
                if (this.$refs.photo_fr.files){
                    this.form.fr_image = this.$refs.photo_fr.files[0]
                }
                if (this.$refs.photo_en.files){
                    this.form.en_image = this.$refs.photo_en.files[0]
                }
                this.$inertia.post('/admin/actualite/'+this.actualite.id, this.form , {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => this.$refs.submitButton.stopLoading(),
                    onSuccess: (page) => {
                        if (page.props.errors.addActualite) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()
                        this.close();
                    },
                    preserveScroll: true

                })
            },

            loadCategories(){
                axios.get('/admin/categories-actualites/all')
                    .then(response => {
                        this.categories = response.data.data;
                    });
            },
            loadTypes(){
                axios.get('/admin/types-actualites/all')
                    .then(response => {
                        this.types = response.data.data;
                    });
            },
            addActualite(){

                if (this.$refs.photo_fr.files){
                    this.form.fr_image = this.$refs.photo_fr.files[0]
                }
                if (this.$refs.photo_en.files){
                    this.form.en_image = this.$refs.photo_en.files[0]
                }
                this.$inertia.post('/admin/actualite', this.form, {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => {
                        this.$refs.submitButton.stopLoading();
                    },
                    onSuccess: (page) => {
                        if (page.props.errors.addFormation) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()

                        this.close();
                    },
                    preserveScroll: true,
                });
            },
            updateProgramme(){
                this.form._method = 'PUT';

                this.$inertia.post('/admin/programme/'+this.formation.programme.id, this.form , {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => this.$refs.submitButton.stopLoading(),
                    onSuccess: (page) => {
                        if (page.props.errors.addActualite) {
                            return;
                        }
                        this.$emit('updated');
                        this.close();
                    },
                    preserveScroll: true

                })
            }

        },
        mounted() {
            this.loadCategories();
            this.loadTypes();
            if (this.actualite){
                this.form.fr_titre_mag = this.actualite.fr_titre_mag;
                this.form.en_titre_mag = this.actualite.en_titre_mag;
                this.form.fr_description = this.actualite.fr_description;
                this.form.en_description = this.actualite.en_description;
                this.form.apercu = this.actualite.apercu;
                this.form.en_apercu = this.actualite.en_apercu;
                this.form.fr_image_old = this.actualite.fr_image;
                this.form.en_image_old = this.actualite.en_image;
                this.form.categorie_id  = this.actualite.categorie.id;
                this.form.type_id = this.actualite.type.id;
            }
        }
    }
</script>

