<template>
    <app-layout>
        <div>
            <div class="col-xs-12">
                <div class="page-title-box">
                    <h4 class="page-title">Demandes interim</h4>
                    <ol class="breadcrumb p-0 m-0">
                        <li>
                            <inertia-link href="/admin/dashboard">Dashboard</inertia-link>
                        </li>
                        <li class="active">
                            Demandes d'intérim
                        </li>
                    </ol>
                    <div class="clearfix"></div>
                </div>
            </div>

            <div class="col-xs-12 ">
                <div class="col-xs-12 m-b-3 row">
                    <span class="h3"> Demandes d'intérim </span>
                    <div>
                        <datatable
                                :fetch-url="$page.props.app.url+'admin/demandes-interims'"
                                :columns="interimColumns"
                                :links="columnsLink"
                                :color="'table-bordered-teal'"
                                :btns="btnColumns"
                                @show="showInterim($event)"
                                @modifier="suivreDemandeRec($event)"
                                @supprimer="cloturerInterim($event)"
                                ref="interimTable"
                        ></datatable>
                    </div>
                </div>
            </div>
            <show-demande-interim :show="showDemande" :demandeToShow="demandeToShow" @close="close" ></show-demande-interim>
            <suivre-interim-form :show="showInterimForm" :demandeToEdit="demandeToEdit" @close="closeForm"  ></suivre-interim-form>
        </div>
    </app-layout>
</template>

<script>
    import AppLayout from "../../Layout/AppLayout";
    import Datatable from "@/Shared/Datatable";
    import ShowDemandeInterim from "@/Pages/Interim/ShowDemandeInterim";
    import SuivreInterimForm from "@/Pages/Interim/SuivreInterimForm";
    export default {
        components:{
            AppLayout,
            ShowDemandeInterim,
            SuivreInterimForm,
            Datatable
        },
        props: ['data'],
        data() {
            return {
                show: false,
                showDemande:false,
                showInterimForm: false,
                demandeToShow: '',
                editFormation: false,
                demandeToEdit: '',
                interimColumns:[{name: 'id', titre: '#ID'},
                    {name: 'prenom'},{name: 'nom', titre: 'Titre EN'},
                    {name: 'nom_societe'},
                    {name: 'poste'},{name: 'nombre_poste', size:25}],
                btnColumns:[
                    {name: 'Voir', action: 'show', classes: 'btn btn-xs btn-teal'},
                    {name: 'Suivre', action: 'edit', classes: 'btn btn-xs btn-brown'},
                    {name: 'Cloturer', action: 'delete', classes: 'btn btn-xs btn-danger'}],
                columnsLink:[
                   // {name: 'Programme', url: '/admin/programme-formation/', classes: 'btn btn-xs btn-teal'}
                    ],
                form: this.$inertia.form({
                    activite : '',
                    description : '',
                    demande_id : '',
                    clickVoir : 1,
                })
            }
        },
        methods: {
            close(){
                this.showDemande = false;
            },
            closeForm(){
                this.showInterimForm = false;
            },
            reloadData(){
                this.$refs.interimTable.reloadData();
            },
            suivreDemandeRec(data){
                this.demandeToEdit = data;
                this.showInterimForm = true;
            },
            showInterim(data){
                this.form.activite = 'Consulter la demande';
                this.form.demande_id = data.id;

                    this.$inertia.post('/admin/suivi-interim', this.form, {

                    onSuccess: (page) => {
                        this.demandeToShow = data;
                        this.showDemande = true;
                    },
                    preserveScroll: true,
                });

            },
            cloturerInterim (data){
                Swal.fire({
                    title: 'Êtes-vous sûr?',
                    text: "Vous ne pourrez plus revenir en arrière!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui, cloturer la demande!'
                }).then((result) => {
                    if(result.value){
                        data._method = 'PUT';

                        this.$inertia.post('/admin/demande-interim/'+data.id, data,{
                            onSuccess: (page) => {
                                this.reloadData();
                            },
                            onError: (page) => {

                            },
                            preserveScroll: true,
                        }).catch(() => {


                        });
                    }
                });
            },


        },
        mounted() {
            //this.loadDomaines();
        }
    }
</script>

