<template>
    <div>
        <modal :show="show" @close="close()">
            <template #modalHead>
                <div>
                    Ajout d'une formation
                </div>
            </template>
            <template #modalBody>
                <div>
                    <form class="form-horizontal">
                        <div class="form-group">
                            <label class="col-xs-12 ">Titre de la formation (Français)</label>
                            <div class="col-xs-12">
                                <input type="text" placeholder="Titre de la formation" v-model="form.fr_titre_formation" class="form-control" value="Some text value...">
                                <input-error :formName="'addFormation'" :message="'fr_titre_formation'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Titre de la formation (en anglais)</label>
                            <div class="col-xs-12">
                                <input type="text" placeholder="Formation title" v-model="form.en_titre_formation" class="form-control" value="Some text value...">
                                <input-error :formName="'addFormation'" :message="'en_titre_formation'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Catégorie </label>
                            <div class="col-xs-12">
                                <v-select  placeholder="Selectionnez une catégorie" label="fr_libelle_categorie" :options="categories"
                                           :reduce="categorie => categorie.id" v-model="form.categorie_id" ></v-select>
                                <input-error :formName="'addFormation'" :message="'duree'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Description de la formation (Français)</label>
                            <div class="col-xs-12">
                                <ckeditor v-model="form.fr_description" :config="editorConfig"></ckeditor>
                                <input-error :formName="'addFormation'" :message="'fr_description'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Description de la formation (en Anglais)</label>
                            <div class="col-xs-12">
                                <ckeditor v-model="form.en_description" :config="editorConfig"></ckeditor>
                                <input-error :formName="'addFormation'" :message="'en_description'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Pour qui ? (en Français)</label>
                            <div class="col-xs-12">
                                <ckeditor v-model="form.fr_pour_qui" :config="editorConfig"></ckeditor>
                                <input-error :formName="'addFormation'" :message="'fr_pour_qui'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Pour qui ? (en Anglais)</label>
                            <div class="col-xs-12">
                                <ckeditor v-model="form.en_pour_qui" :config="editorConfig"></ckeditor>
                                <input-error :formName="'addFormation'" :message="'en_pour_qui'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Prérequis (en français)</label>
                            <div class="col-xs-12">
                                <ckeditor v-model="form.fr_pres_requis" :config="editorConfig"></ckeditor>
                                <input-error :formName="'addFormation'" :message="'fr_pres_requis'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Prérequis (en anglais)</label>
                            <div class="col-xs-12">
                                <ckeditor v-model="form.en_pres_requis" :config="editorConfig"></ckeditor>
                                <input-error :formName="'addFormation'" :message="'en_pres_requis'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Durée  de la formation (en français)</label>
                            <div class="col-xs-12">
                                <input type="text" v-model="form.fr_duree" class="form-control" value="Some text value...">
                                <input-error :formName="'addFormation'" :message="'fr_duree'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-12 ">Durée  de la formation</label>
                            <div class="col-xs-12">
                                <input type="text" v-model="form.en_duree" class="form-control" value="Some text value...">
                                <input-error :formName="'addFormation'" :message="'en_duree'" class="mt-2" />
                            </div>
                        </div>
                        <div class="form-group">
                            <div >
                                <label class="control-label">Image illustrative (français)</label>
                                <input type="file" ref="photo_fr" @change="updatePreview"  class="filestyle" data-buttonbefore="true" id="filestyle-8" tabindex="-1" style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
                                <div class="bootstrap-filestyle input-group">
                                <span class="group-span-filestyle input-group-btn" tabindex="0">
                                    <label for="filestyle-8" class="btn btn-default ">
                                        <span class="icon-span-filestyle glyphicon glyphicon-folder-open"></span>
                                        <span class="buttonText">Choisir un fichier</span>
                                    </label>
                                </span>
                                    <input type="text" class="form-control " placeholder="" disabled="">
                                </div>
                            </div>
                            <div v-show="form.fr_image" class="float-left">
                              <span class="block w-20 h-20 rounded-full"
                                    :style="'width: 5rem; height: 5rem; border-radius: 9px; display: block; background-size: cover; background-repeat: no-repeat; background-position: center center; background-image: url(\'' + form.fr_image + '\');'">
                              </span>
                            </div>
                            <div v-show="form.fr_image_old" class="float-left">
                                  <span class="block w-20 h-20 rounded-full"
                                        :style="'width: 5rem; height: 5rem; border-radius: 9px; display: block; background-size: cover; background-repeat: no-repeat; background-position: center center; background-image: url(\'../storage/images-formation/' + form.fr_image_old + '\');'">
                                  </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div >
                                <label class="control-label">Image illustrative (en anglais)</label>
                                <input type="file" ref="photo_en" @change="updatePreviewEng"  class="filestyle" data-buttonbefore="true" id="filestyle-9" tabindex="-1" style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
                                <div class="bootstrap-filestyle input-group">
                                <span class="group-span-filestyle input-group-btn" tabindex="0">
                                    <label for="filestyle-9" class="btn btn-default ">
                                        <span class="icon-span-filestyle glyphicon glyphicon-folder-open"></span>
                                        <span class="buttonText">Choisir un fichier</span>
                                    </label>
                                </span>
                                    <input type="text" class="form-control " placeholder="" disabled="">
                                </div>
                            </div>
                            <div v-show="form.en_image" class="float-left">
                                  <span class="block w-20 h-20 rounded-full"
                                        :style="'width: 5rem; height: 5rem; border-radius: 9px; display: block; background-size: cover; background-repeat: no-repeat; background-position: center center; background-image: url(\'' + form.en_image + '\');'">
                                  </span>
                            </div>
                            <div v-show="form.en_image_old" class="float-left">
                                  <span class="block w-20 h-20 rounded-full"
                                        :style="'width: 5rem; height: 5rem; border-radius: 9px; display: block; background-size: cover; background-repeat: no-repeat; background-position: center center; background-image: url(\'../storage/images-formation/' + form.en_image_old + '\');'">
                                  </span>
                            </div>
                        </div>



                    </form>
                </div>
            </template>
            <template #modalFooter>
                <div>
                    <loading-btn v-if="!editMode" @click="addFormation" ref="submitButton" :classes="'btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right'" >Ajouter</loading-btn>
                    <loading-btn v-if="editMode" @click="updateFormation" ref="submitButton" :classes="'btn btn-inverse waves-light waves-effect w-md m-b-5 pull-right'" >Enregistrer</loading-btn>
                </div>
            </template>
        </modal>
    </div>
</template>
<style>
    .align-items-center {
        display: flex;
        align-items: center;
    }
</style>
<script>
    import Modal from '@/Shared/Modal';
    import InputError from "@/Shared/InputError";
    import LoadingBtn from "@/Shared/LoadingBtn";
    export default {
        components: {
            Modal,
            InputError,
            LoadingBtn
        },
        data(){
            return{
                editorConfig: {
                    language: 'fr',
                    //uiColor: '#9AB8F3',
                    height: 100
                },
                categories:[],
                preview: null,
                form: this.$inertia.form({
                    fr_titre_formation : '',
                    en_titre_formation : '',
                    fr_description: '',
                    en_description: '',
                    fr_pour_qui: '',
                    en_pour_qui: '',
                    fr_pres_requis: '',
                    en_pres_requis: '',
                    fr_duree: '',
                    en_duree: '',
                    with_certif:0,
                    presentiel:0,
                    categorie_id:'',
                    fr_image: null,
                    en_image: null,
                }, {
                    bag: 'addFormation',
                })
            }
        },
        props:{
            show : {
                required: true,
            },
            editMode: {
                default: false,
            },
            formationToEdit: {
                default: false,
            }
        },
        methods:{
            close(){
                this.form.reset();
                this.$emit('close');
            },
            updatePreview() {
                const reader = new FileReader();
                if(this.$refs.photo_fr.files){
                    let size = this.$refs.photo_fr.files[0].size/1024;
                    let type = this.$refs.photo_fr.files[0].type.split('/').pop();

                    let accepted_formats = ['jpeg','jpg', 'png', 'webp'];

                    if (size>1024){
                        alert("Fichier lourd. ("+Math.floor(size/1024)+" MB) Compressez l'image  avant de le poster !");
                        return '';
                    }
                    if (!accepted_formats.includes(type)){

                        alert("ERREUR TYPE DE FICHIER ! Choississez un fichier de type : "+accepted_formats);
                        return '';
                    }
                    reader.onload = (e) => {
                        this.form.fr_image = e.target.result;
                    };

                    reader.readAsDataURL(this.$refs.photo_fr.files[0]);
                }
            },
            updatePreviewEng() {
                const reader = new FileReader();
                if(this.$refs.photo_en.files){
                    let size = this.$refs.photo_en.files[0].size/1024;
                    let type = this.$refs.photo_en.files[0].type.split('/').pop();

                    let accepted_formats = ['jpeg','jpg', 'png', 'webp'];

                    if (size>1024){
                        alert("Fichier lourd. ("+Math.floor(size/1024)+" MB) Compressez l'image  avant de le poster !");
                        return '';
                    }
                    if (!accepted_formats.includes(type)){

                        alert("ERREUR TYPE DE FICHIER ! Choississez un fichier de type : "+accepted_formats);
                        return '';
                    }
                    reader.onload = (e) => {
                        this.form.en_image = e.target.result;
                    };

                    reader.readAsDataURL(this.$refs.photo_en.files[0]);
                }
            },
            storePhoto() {
                if (this.$refs.photo) {
                    this.form.photo = this.$refs.photo.files[0]
                }

                this.form.post(route('photo.store'), {
                    preserveScroll: true
                });
            },
            loadCategories(){
                axios.get('/admin/categories/all')
                    .then(response => {
                        this.categories = response.data.data;
                    });
            },
            booleanValue(value){
                let index = value.indexOf("Oui");

                if(index >=0){
                   return 1;
               }
               return 0;
            },
            addFormation(){

                if (this.$refs.photo_fr.files){
                    this.form.fr_image = this.$refs.photo_fr.files[0]
                }
                if (this.$refs.photo_en.files){
                    this.form.en_image = this.$refs.photo_en.files[0]
                }
                this.$inertia.post('/admin/formation', this.form, {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => {
                        this.$refs.submitButton.stopLoading();
                    },
                    onSuccess: (page) => {
                        if (page.props.errors.addFormation) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()

                        this.close();
                    },
                    preserveScroll: true,
                });
            },
            updateFormation(){
                this.form._method = 'PUT';
                if (this.$refs.photo_fr.files){
                    this.form.fr_image = this.$refs.photo_fr.files[0]
                }
                if (this.$refs.photo_en.files){
                    this.form.en_image = this.$refs.photo_en.files[0]
                }
                this.$inertia.post('/admin/formation/'+this.formationToEdit.id, this.form , {
                    onStart: () => this.$refs.submitButton.startLoading() ,
                    onFinish: () => this.$refs.submitButton.stopLoading(),
                    onSuccess: (page) => {
                        if (page.props.errors.addFormation) {
                            return;
                        }
                        this.$emit('updated');
                        this.form.reset()
                        this.close();
                    },
                    preserveScroll: true

                })
            }
        },
        watch: {
            formationToEdit() {
                this.form.fr_titre_formation = this.formationToEdit.fr_titre_formation;
                this.form.en_titre_formation = this.formationToEdit.en_titre_formation;
                this.form.fr_description = this.formationToEdit.fr_description;
                this.form.en_description = this.formationToEdit.en_description;
                this.form.fr_pour_qui = this.formationToEdit.fr_pour_qui;
                this.form.en_pour_qui = this.formationToEdit.en_pour_qui;
                this.form.fr_pres_requis = this.formationToEdit.fr_pres_requis;
                this.form.en_pres_requis = this.formationToEdit.en_pres_requis;
                this.form.fr_duree = this.formationToEdit.fr_duree;
                this.form.en_duree = this.formationToEdit.en_duree;
                this.form.fr_image_old = this.formationToEdit.fr_image_old;
                this.form.en_image_old = this.formationToEdit.en_image_old;
                this.form.categorie_id = this.formationToEdit.categorie_id;
            }
        },

        mounted() {
            this.loadCategories();
        }
    }
</script>