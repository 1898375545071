

require('./bootstrap');

import { InertiaApp } from '@inertiajs/inertia-vue'
import Vue from 'vue'
import vSelect from 'vue-select'
import Vuetable from 'vuetable-2/src/components/Vuetable';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { InertiaProgress } from '@inertiajs/progress'
import VueTablePagination from 'vuetable-2/src/components/VueTablePagination';
import VueTablePaginationDropDown from 'vuetable-2/src/components/VueTablePaginationDropDown';
import VueTablePaginationInfo from 'vuetable-2/src/components/VueTablePaginationInfo';
import tinymce from '@tinymce/tinymce-vue';
import Swal from 'sweetalert2'
import { VueHammer } from 'vue2-hammer'
import CKEditor from 'ckeditor4-vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

Vue.component("DatePicker", DatePicker);
Vue.component("vuetable", Vuetable);
Vue.component("vuetable-pagination", VueTablePagination);
Vue.component("vuetable-pagination-dropdown", VueTablePaginationDropDown);
Vue.component("vuetable-pagination-info", VueTablePaginationInfo);
Vue.component('v-select', vSelect);
Vue.component('Editor', tinymce);
const moment = require('moment')
require('moment/locale/es')
moment.locale('fr');
Vue.use( CKEditor );
Vue.use(VueSweetalert2);
Vue.use(InertiaApp)
Vue.use(VueHammer)
Vue.filter('formatedDate', function(date) {
    return moment(date).format(' Do MMMM YYYY');
});
InertiaProgress.init({delay: 250,color: '#29d',includeCSS: true,showSpinner: false,});

Vue.prototype.$bus = new Vue();
window.Swal = Swal;
Vue.mixin({
    methods: {
        route: (name, params, absolute, config = Ziggy) => route(name, params, absolute, config),
    },
});
const app = document.getElementById('app')
Vue.mixin({
    methods: {

        error(field, errorBag = 'default') {
            if (!this.$page.errors.hasOwnProperty(errorBag)) {
                return null;
            }

            if (this.$page.errors[errorBag].hasOwnProperty(field)) {
                return this.$page.errors[errorBag][field][0];
            }

        }
    }
});
new Vue({
    render: h => h(InertiaApp, {
        props: {
            initialPage: JSON.parse(app.dataset.page),
            resolveComponent: name => require(`./Pages/${name}`).default,
            resolveErrors: page => (page.props.errors || {}),

        },
    }),
}).$mount(app)
