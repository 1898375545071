<template>
    <app-layout>
        <div>
            <div class="col-xs-12">
                <div class="page-title-box">
                    <h4 class="page-title">Sessions Formation</h4>
                    <ol class="breadcrumb p-0 m-0">
                        <li>
                            <inertia-link href="/admin/dashboard">Dashboard</inertia-link>
                        </li>
                        <li>
                            <inertia-link href="/admin/formation">Formations </inertia-link>
                        </li>
                        <li class="active">
                            Sessions
                        </li>
                    </ol>
                    <div class="clearfix"></div>
                </div>
            </div>

            <div class="col-xs-12 ">
                <div class="col-xs-12 m-b-3 row">
                    <span class="h3">Sessions de formation </span>
                    <button type="button" class="btn btn-teal waves-light waves-effect w-md m-b-5 pull-right" @click="ajouterFormation()">Ajouter</button>

                    <div>

                        <datatable
                                :fetch-url="$page.props.app.url+'admin/sessions'"
                                :columns="sessionColumns"
                                :color="'table-bordered-teal'"
                                :btns="btnColumns"
                                :noShort="noShort"
                                @modifier="editingSession($event)"
                                @supprimer="deletingSession($event)"
                                ref="sessionTable"
                        ></datatable>
                    </div>
                </div>
            </div>
            <session-form :show="showSessionForm" :editMode="editSession" :sessionToEdit="sessionToEdit" @close="close"  @updated="reloadSessions()"></session-form>
        </div>
    </app-layout>
</template>

<script>
    import AppLayout from "@/Layout/AppLayout";
    import SessionForm from "@/Pages/Formation/Sessions/SessionForm";
    import Datatable from "@/Shared/Datatable";
    export default {
        components:{
            AppLayout,
            SessionForm,
            Datatable
        },
        props: ['data'],
        data() {
            return {
                show: false,
                showSessionForm:false,
                editSession: false,
                sessionToEdit: '',
                sessionColumns:[{name: 'id', titre: '#ID'},{name: 'formation', titre: 'Formation'},
                    {name: 'date_debut', titre: 'Début', type:'date'},{name: 'tarif', titre: 'Tarif'}
                    ,{name: 'nombre_place', titre: 'nombre_place'},{name: 'with_certif', titre: 'WITH CERTIF'},{name: 'presentiel', titre: 'PRESENTIEL'}],
                btnColumns:[
                    {name: 'Editer', action: 'edit', classes: 'btn btn-xs btn-warning'},
                    {name: 'Supprimer', action: 'delete', classes: 'btn btn-xs btn-danger'}],
               /* columnsLink:[
                    {name: 'Programme', url: '/admin/programme-formation/', classes: 'btn btn-xs btn-teal'}],*/
                noShort: ['formation'],
            }
        },
        methods: {
            close(){
                this.$emit('close');
                this.show = false;
                this.showSessionForm = false;
            },
            reloadSessions(){
                this.$refs.sessionTable.reloadData();
            },
            editingSession(data){
                this.sessionToEdit = data;
                this.editSession = true;
                this.showSessionForm = true;
            },
            ajouterFormation(){
                this.showSessionForm = true;
            },

            deletingSession (data){
                Swal.fire({
                    title: 'Êtes-vous sûr?',
                    text: "Vous ne pourrez plus revenir en arrière!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui, supprimez-le!'
                }).then((result) => {
                    if(result.value){
                        data._method = 'DELETE';

                        this.$inertia.post('/admin/session-formation/'+data.id, data,{
                            onSuccess: (page) => {
                                this.reloadSessions();
                            },
                            onError: (page) => {

                            },
                            preserveScroll: true,
                        }).catch(() => {

                        });
                    }
                });
            },


        },
        mounted() {
            //this.loadDomaines();
        }
    }
</script>

